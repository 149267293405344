
import { put } from '@/services/http';
import { Component, Vue } from 'vue-property-decorator';
import { TemplateReportList } from '@/collections/template_reports';
import { TagList } from '@/collections/tags';
import { TemplateReportModel } from '@/models/template_report';
import { TagModel } from '@/models/tag';
import { Container, Draggable } from 'vue-smooth-dnd';

@Component({
  components: {
    Container,
    Draggable,
  },
})
export default class TemplateReports extends Vue {
  public isLoading: boolean = false;
  public loading: boolean = false;
  public saving: boolean = false;
  public name: string = '';
  public templateReports: TemplateReportList = new TemplateReportList();
  public tags: TagList = new TagList();
  public tag: TagModel = new TagModel();

  public async created() {
    this.isLoading = true;
    await this.tags.fetch();
    this.tag.id = this.tags.items[0].id;
    await this.fetchData();
    this.isLoading = false;
  }

  public async fetchData() {
    this.loading = true;
    this.templateReports = new TemplateReportList();
    await this.templateReports.fetchFull(
      {
        tag: this.tag.id,
      },
    );
    this.sort();
    this.loading = false;
  }

  public onDrop(result: any) {
    this.templateReports = this.applyDrag(this.templateReports, result);
  }

  public async saveSort() {
    this.saving = true;
    const templateReports = [];
    for (const item of this.templateReports.items) {
      templateReports.push({ id: item.id });
    }
    const res: any = await put(`/template_reports`, {
      tag: { id: this.tag.id },
      templateReports,
    });
    this.saving = false;
  }

  private applyDrag(result: TemplateReportList, dragResult: any) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return result;
    }

    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.items.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.items.splice(addedIndex, 0, itemToAdd);
    }

    return result;
  }

  private sort() {
    const compare = (a: TemplateReportModel, b: TemplateReportModel) => {
      if (!a.sortOrder || !a.sortOrder.tag) {
        return 1;
      }
      if (!b.sortOrder || !b.sortOrder.tag) {
        return -1;
      }
      if (a.sortOrder.tag[this.tag.id] < b.sortOrder.tag[this.tag.id]) {
        return -1;
      }
      if (a.sortOrder.tag[this.tag.id] > b.sortOrder.tag[this.tag.id]) {
        return 1;
      }
      return 0;
    };
    this.templateReports.items = this.templateReports.items.sort(compare);
  }
}
