import { get, post } from '@/services/http';
import { StoreModel } from '@/models/store';
import List from './list';

interface StoreParams {
  keyword?: string;
}

export class StoreList extends List<StoreModel> {
  public lastPage!: number;

  public async fetch(
    page: number = 1,
    pageSize: number = 50,
    params: StoreParams = {},
  ) {
    const data: any = {
      page,
      ...params,
    };
    const res: any = await get(`/stores`, data);
    const stores = res.data.rows || [];
    this.lastPage = Number((res.data.count / pageSize).toFixed(0));
    if (res.data.count / pageSize > this.lastPage) {
      this.lastPage = this.lastPage + 1;
    }
    for (const s of stores) {
      const store: StoreModel = new StoreModel();
      store.mapData(s);
      this.add(store);
    }
  }

  public async getPaidStores() {
    const data: any = {};
    const res: any = await get(`/paid_stores`, data);
    const stores = res.data || [];
    for (const s of stores) {
      const store: StoreModel = new StoreModel();
      store.mapData(s);
      this.add(store);
    }
  }

  public async getPassedTrialStores() {
    const data: any = {};
    const res: any = await get(`/passed_trial_stores`, data);
    const stores = res.data || [];
    for (const s of stores) {
      const store: StoreModel = new StoreModel();
      store.mapData(s);
      this.add(store);
    }
  }

  public async getActiveTrialStores() {
    const data: any = {};
    const res: any = await get(`/active_trial_stores`, data);
    const stores = res.data || [];
    for (const s of stores) {
      const store: StoreModel = new StoreModel();
      store.mapData(s);
      this.add(store);
    }
  }
}
