import { get, post } from '@/services/http';
import { FaqModel } from '@/models/faq';
import List from './list';

export class FaqList extends List<FaqModel> {
  public async fetch() {
    const res: any = await get(`/faqs`, {});
    const faqs = res.data || [];
    for (const s of faqs) {
      const faq = new FaqModel();
      faq.mapData(s);
      this.add(faq);
    }
  }

  public async load() {
    const res: any = await post(`/faqs/load`);
  }
}
