import { get, post } from '@/services/http';
import { DimensionColumnModel } from '@/models/dimension_column';
import List from './list';

export class DimensionColumnList extends List<DimensionColumnModel> {
  public async fetch() {
    const res: any = await get(`/active_dimensions`, {});
    const dimensionColumns = res.data || [];
    for (const data of dimensionColumns) {
      const dimensionColumn = new DimensionColumnModel();
      dimensionColumn.mapData(data);
      this.add(dimensionColumn);
    }
  }
}
