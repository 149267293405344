import { get } from '@/services/http';
import { DimensionList } from '@/collections/dimensions';
import { AnalyticsTypeList } from '@/collections/analytics_types';

export class MeasureModel {
  public id: number;
  public name: string;
  public code: string;
  public analyticsTypes: AnalyticsTypeList;
  public dimensions: DimensionList;
  public isOlap?: boolean;
  public isPositiveTrend?: boolean;
  public hasMetric?: boolean;
  public hasDimension?: boolean;
  public hasTimerange?: boolean;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.dimensions = new DimensionList();
    this.analyticsTypes = new AnalyticsTypeList();
  }

  public async fetch() {
    const res: any = await get(`/measures/${this.code}`);
    this.mapData(res.data);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.luis_mapping;
    this.isOlap = data.is_olap;
    this.isPositiveTrend = data.is_positive_trend;
    this.hasMetric = data.has_metric;
    this.hasDimension = data.has_dimension;
    this.hasTimerange = data.has_timerange;
    this.dimensions.mapData(data.dimensions);
    this.analyticsTypes.mapData(data.analytic_types);
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }
}
