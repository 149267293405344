import { get } from '@/services/http';
import { DefaultMetricModel } from '@/models/default_metric';
import List from './list';

export class DefaultMetricList extends List<DefaultMetricModel> {
  public async fetch() {
    const res: any = await get(`/default_metrics`, {});
    const defaultMetrics = res.data || [];
    for (const s of defaultMetrics) {
      const defaultMetric: DefaultMetricModel = new DefaultMetricModel();
      defaultMetric.mapData(s);
      this.add(defaultMetric);
    }
  }
}
