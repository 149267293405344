import { get, post } from '@/services/http';
import { TemplateReportModel } from '@/models/template_report';
import List from './list';

interface TemplateReportParams {
  measure?: string;
  dimension?: string;
  analyticsType?: string;
  name?: string;
  tag?: number;
}

export class TemplateReportList extends List<TemplateReportModel> {
  public lastPage!: number;

  public async fetch(
    page: number = 1,
    pageSize: number = 50,
    params: TemplateReportParams = {},
  ) {
    const data: any = {
      page,
      per_page: pageSize,
      ...params,
    };
    const res: any = await get(`/template_reports`, data);
    const templateReports = res.data.rows || [];
    this.lastPage = Number((res.data.count / pageSize).toFixed(0));
    if (res.data.count / pageSize > this.lastPage) {
      this.lastPage = this.lastPage + 1;
    }
    for (const s of templateReports) {
      const templateReport: TemplateReportModel = new TemplateReportModel();
      templateReport.mapData(s);
      this.add(templateReport);
    }
  }

  public async fetchFull(
    params: TemplateReportParams = {},
  ) {
    const data: any = {
      ...params,
    };
    const res: any = await get(`/template_reports/full`, data);
    const templateReports = res.data || [];
    for (const s of templateReports) {
      const templateReport: TemplateReportModel = new TemplateReportModel();
      templateReport.mapData(s);
      this.add(templateReport);
    }
  }

  public async load() {
    const res: any = await post(`/template_reports/load`);
  }
}
