
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FaqModel } from '@/models/faq';

@Component({
  components: {
  },
})
export default class FaqEditPage extends Vue {
  public isLoading: boolean = true;
  public loading: boolean = false;
  public faq: FaqModel = new FaqModel();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    this.isLoading = true;
    this.faq.id = Number(this.$route.params.id);
    await this.faq.fetch();
    this.isLoading = false;
  }

  public async deleteFaq() {
    this.loading = true;
    await this.faq.del();
    this.loading = false;
    await this.$router.push('/faqs');
  }

  public async inactiveFaq() {
    this.loading = true;
    await this.faq.inactive();
    this.loading = false;
    await this.$router.push('/faqs');
  }

  public async activeFaq() {
    this.loading = true;
    await this.faq.active();
    this.loading = false;
    await this.$router.push('/faqs');
  }

  public async updateFaq() {
    if (!this.faq.question || this.faq.question === '') {
      this.isError = true;
      this.messageError = 'Question is a required param';
      return;
    }
    if (!this.faq.answer || this.faq.answer === '') {
      this.isError = true;
      this.messageError = 'Question is a required param';
      return;
    }

    this.loading = true;
    await this.faq.update();
    this.loading = false;
    await this.$router.push('/faqs');
  }
}
