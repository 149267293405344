import { get, post } from '@/services/http';
import { PluginRuleModel } from '@/models/plugin_rule';
import List from './list';

export class PluginRuleList extends List<PluginRuleModel> {
  public mapData(data: any = []) {
    for (const item of data) {
      const pluginRule = new PluginRuleModel();
      pluginRule.mapData(item);
      this.add(pluginRule);
    }
  }

  public async fetch(data: any = {}) {
    const res: any = await get(`/plugin_rules`, data);
    const pluginRules = res.data || [];
    for (const data of pluginRules) {
      const pluginRule: PluginRuleModel = new PluginRuleModel();
      pluginRule.mapData(data);
      this.add(pluginRule);
    }
  }

  public async fetchOnePlugin(pluginId: number) {
    const res: any = await get(`/plugin_rules/${pluginId}`, {});
    const pluginRules = res.data || [];
    for (const data of pluginRules) {
      const pluginRule: PluginRuleModel = new PluginRuleModel();
      pluginRule.mapData(data);
      this.add(pluginRule);
    }
  }
}
