
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DashboardModel} from '@/models/dashboard';
import dateFormat from 'dateformat';
import ReportCardPieChart from '@/components/ReportCardPieChart.vue';

interface Range {
  start?: any;
  end?: any;
}

@Component({
  components: {
    ReportCardPieChart,
  },
})
export default class Home extends Vue {
  public range: Range = {};
  public dateRangeMenu: boolean = false;
  public dashboard: DashboardModel = new DashboardModel();
  public isLoadedReportPackageType: boolean = false;
  public reportPackageType: any = null;

  public async created() {
    this.dashboard.getStoreTotalCount();
    this.dashboard.getPaidStoreTotalCount();
    this.dashboard.getPassedTrialStoreTotalCount();
    this.dashboard.getIncommingStoreTotalCount();
    this.dashboard.getPassedTrialStoreAvgPrice();
    this.dashboard.getNotCompleteOnboardStoreTotalCount();

    // get paid store count by shopify package
    await Promise.all([
      this.dashboard.getPaidStoreTotalCount('Basic Shopify'),
      this.dashboard.getPaidStoreTotalCount('Professional'),
      this.dashboard.getPaidStoreTotalCount('Advanced Shopify'),
      this.dashboard.getPaidStoreTotalCount('Shopify Plus'),
      this.dashboard.getPaidStoreTotalCount('Free'),
    ]);

    // prepare dataset
    this.reportPackageType = {
      columns: ['Basic Shopify', 'Professional', 'Advanced Shopify', 'Shopify Plus', 'Free'],
      data: [
        this.dashboard.paidBasicStoreTotalCount,
        this.dashboard.paidProfessionalStoreTotalCount,
        this.dashboard.paidAdvancedStoreTotalCount,
        this.dashboard.paidPlusStoreTotalCount,
        this.dashboard.paidFreeStoreTotalCount,
      ],
    };
    this.isLoadedReportPackageType = true;
  }

  public get customRangeText() {
    if (this.range.start && this.range.end) {
      return `${dateFormat(this.range.start, 'mmm d, yyyy')} - ${dateFormat(
          this.range.end,
          'mmm d, yyyy',
      )}`;
    }
    return 'Select daterange';
  }

  @Watch('range', {immediate: true, deep: true})
  private async onRangeChanged(newVal: any) {
    if (this.range.start && this.range.end) {
      this.dashboard.startDate = this.range.start;
      this.dashboard.endDate = this.range.end;
      this.dateRangeMenu = false;
    }
  }
}
