import { get } from '@/services/http';

export class ReportModel {

  public measure: string;
  public dimension: string;
  public timerange!: string[];
  public analyticsType!: string;
  public pivotOption!: string;
  public reportColumns!: any;


  constructor(data: any = {}) {
    this.measure = data.measure;
    this.dimension = data.dimension;
    this.timerange = data.timerange;
    this.analyticsType = data.analyticsType;
    this.pivotOption = data.pivotOption;
  }

  public async fetch() {
    const data: any = {
      measure: this.measure,
      dimension: this.dimension,
      isDetail: true,
      isMetric: false,
      comparison_period: 'last_period',
      page: 1,
      per_page: 1,
      from: '2023-05-02',
      to: '2023-05-15',
    };
    if (this.analyticsType) {
      data.analyticsType = this.analyticsType;
    }
    if (this.pivotOption) {
      data.pivotOption = this.pivotOption;
    }
    const res: any = await get('/questions/report?merchant_id=2120', data);
    if (res.data && res.data[0]) {
      this.reportColumns = res.data[0].columns;
    }
  }
}
