
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DefaultMetricModel } from '@/models/default_metric';
import { MeasureList } from '@/collections/measures';

@Component({
  components: {
  },
})
export default class TemplateReportNewPage extends Vue {
  public isLoading: boolean = true;
  public defaultMetric: DefaultMetricModel = new DefaultMetricModel();
  public measures: MeasureList = new MeasureList();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    this.isLoading = true;
    await this.getDefault();
    this.isLoading = false;
  }

  public async getDefault() {
    await this.measures.fetch({ hasMetric: true });
  }

  public async createDefaultMetric() {
    if (!this.defaultMetric.measure.code || this.defaultMetric.measure.code === '') {
      this.isError = true;
      this.messageError = 'Measure is a required param';
      return;
    }

    await this.defaultMetric.save();
    await this.$router.push('/default-metrics');
  }
}
