import { post } from '@/services/http';

export default class SessionModel {
  public async create(email: string, password: string) {
    const res: any = await post('/sessions', { email, password });
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('id', res.data.id);
    localStorage.setItem('email', res.data.email);
  }
}
