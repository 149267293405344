import { get, post, del, put } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import dateFormat from 'dateformat';

export class CustomReportModel {
  public id: number;
  public name: string;
  public measure: MeasureModel;
  public dimension?: DimensionModel;
  public analyticsType?: AnalyticsTypeModel;
  public filterTimerange?: string[];
  public defaultRange?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public merchant?: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.merchant = data.merchant;
    this.measure = new MeasureModel();
  }

  public async fetch() {
    const res: any = await get(`/custom_reports/${this.id}`);
    this.mapData(res.data);
  }

  public getFormatedDate(date: string) {
    if (date) {
      return dateFormat(date, 'yyyy-mm-dd');
    }
    return '';
  }
  public async del() {
    const res: any = await del(`/custom_reports/${this.id}`);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.merchant = data.merchant;
    this.measure = new MeasureModel({ code: data.measureCode });
    this.dimension = new DimensionModel({ code: data.dimensionCode });
    this.analyticsType = new AnalyticsTypeModel({ code: data.analyticsTypeCode });
    if (data.startTime && data.endTime) {
      this.filterTimerange = [
          this.getFormatedDate(data.startTime),
          this.getFormatedDate(data.endTime),
          data.defaultRange || 'custom',
        ];
    }
    this.defaultRange = data.defaultRange;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
