import { get, post, put, del } from '@/services/http';
import { RuleList } from '@/collections/rules';

export class PluginPackageModel {
  public id: number;
  public pluginId: number;
  public packageId: number;
  public price: number;
  public originalPrice: number;
  public isDefault: boolean;
  public status: string;

  constructor(data: any = {}) {
    this.id = 0;
    this.pluginId = data.pluginId;
    this.packageId = data.packageId;
    this.price = data.price || 0;
    this.originalPrice = data.originalPrice || 0;
    this.isDefault = data.isDefault || false;
    this.status = data.status;
  }

  public async save() {
    if (!this.id) {
      const res: any = await post(`/plugin_package/create`, {
        pluginId: this.pluginId,
        packageId: this.packageId,
        price: this.price,
        originalPrice: this.originalPrice,
        isDefault: this.isDefault,
      });
      this.mapData(res.data);
    } else {
      const res: any = await put(`/plugin_package/${this.id}`, {
        pluginId: this.pluginId,
        packageId: this.packageId,
        price: this.price,
        originalPrice: this.originalPrice,
        isDefault: this.isDefault,
      });
    }
  }

  public delete() {
    if (this.id) {
      const res: any = del(`/plugin_package/${this.id}`);
    }
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.pluginId = data.pluginId;
    this.packageId = data.packageId;
    this.price = data.price;
    this.originalPrice = data.originalPrice;
    this.isDefault = data.isDefault;
    this.status = data.status;
  }
}
