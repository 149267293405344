
import { Component, Vue } from 'vue-property-decorator';
import { UserDashboardModel } from '@/models/user_dashboard';
import { UserDashboardList } from '@/collections/user_dashboards';


@Component({
  components: {
  },
})
export default class UserDashboards extends Vue {
  public isLoading: boolean = true;
  public dashboards: UserDashboardList = new UserDashboardList();
  public currentPage: number = 1;
  public perPage: number = 50;
  public lastPage: number = 1;
  public keyword: string = '';
  public dialog: boolean = false;

  public async created() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.perPage = Number(this.$route.query.per_page) || 50;
    await this.fetchData();
  }

  public async fetchData() {
    this.isLoading = true;
    this.dashboards = new UserDashboardList();
    await this.dashboards.fetch(
      this.currentPage,
      this.perPage,
      {
        name: this.keyword,
      },
    );
    this.lastPage = this.dashboards.lastPage;
    this.isLoading = false;
  }

  public clearFiler() {
    this.keyword = '';
    this.currentPage = 1;
    this.fetchData();
  }
}
