
import { Component, Vue } from 'vue-property-decorator';
import { TagTypeList } from '@/collections/tag_types';

@Component({
  components: {
  },
})
export default class TagTypes extends Vue {
  public isLoading: boolean = true;
  public tagTypes: TagTypeList = new TagTypeList();
  public status: string = 'INACTIVE';

  public async created() {
    this.loadTagType('ACTIVE');
  }

  public async loadTagType(status: string = '') {
    if (status === 'ACTIVE') {
      this.status = 'INACTIVE';
    } else {
      this.status = 'ACTIVE';
    }
    this.isLoading = true;
    this.tagTypes = new TagTypeList();
    await this.tagTypes.fetch(status);
    this.isLoading = false;
  }
}
