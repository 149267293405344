import { get, post } from '@/services/http';
import { TagTypeModel } from '@/models/tag_type';
import List from './list';

export class TagTypeList extends List<TagTypeModel> {
  public async fetch(status: string = '') {
    const param: any = {};
    if (status) {
      param.status = status;
    }
    const res: any = await get(`/tag_types`, param);
    const tagTypes = res.data || [];
    for (const s of tagTypes) {
      const tagType = new TagTypeModel();
      tagType.mapData(s);
      this.add(tagType);
    }
  }
}
