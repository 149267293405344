
export class OpenaiModel {
  public model: string = 'text-davinci-003';
  public maxTokens: number = 300;
  public temperature: number = 0;
  public apiKey: string = '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';
  public textCompletion: string = '';

  public async fetchDescription(promptStr: string) {

    const axios = require('axios').default;
    let data: any;
    const options = {
      method: 'post',
      url: 'https://api.openai.com/v1/engines/' + this.model + '/completions',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.apiKey,
      },
      data: {
        prompt: promptStr,
        max_tokens: this.maxTokens,
        temperature: this.temperature,
      },
    };

    await axios.request(options).then((response: any) => {
      data = response.data;
    }).catch((error: any) => {
      // do nothing
    });
    return data;
  }
}
