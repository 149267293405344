import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { get, post, put, del } from '@/services/http';
import { ReportCardList } from '@/collections/report_cards';
import { ReportCardModel } from '@/models/report_card';
import { MeasureList } from '@/collections/measures';


export class UserDashboardModel {
  public id: number;
  public name: string;
  public type: string;
  public merchantId?: number;
  public reportCardList: ReportCardList = new ReportCardList();

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
  }

  public async create() {
    const res: any = await post('/dashboards', {
      name: this.name,
    });
    this.mapData(res.data);
  }

  public async publishDashboard() {
    if (this.type === 'PRIVATE') {
      const res: any = await put('dashboards/' + this.id + '/public');
      this.mapData(res.data);
    } else {
      const res: any = await put('dashboards/' + this.id + '/private');
      this.mapData(res.data);
    }
  }
  public async copy() {
    const res: any = await post('/published_dashboards/' + this.id + '/copy');
    this.mapData(res.data.dashboard);
  }

  public async update() {
    const res: any = await put('/dashboards/' + this.id, {
      name: this.name,
    });
  }

  public async delete() {
    const res: any = await del(`/dashboards/${this.id}`);
  }

  public async fetch() {
    if (!this.id) {
      return;
    }
    let res: any = await get(`/dashboards/${this.id}`);
    this.mapData(res.data);
    res = await get(`/dashboards/${this.id}/cards`);
    for (const cardData of res.data) {
      const reportCard = new ReportCardModel();
      reportCard.mapData(cardData);
      this.reportCardList.add(reportCard);
    }
    this.sort();
    // await this.updateSort();
  }

  public async fetchPublic() {
    if (!this.id) {
      return;
    }
    let res: any = await get(`/published_dashboards/${this.id}`);
    this.mapData(res.data);
    res = await get(`/published_dashboards/${this.id}/cards`);
    for (const cardData of res.data) {
      const reportCard = new ReportCardModel();
      reportCard.mapData(cardData);
      this.reportCardList.add(reportCard);
    }
    this.sort();
    // await this.updateSort();
  }

  public sort() {
    const compare = (a: ReportCardModel, b: ReportCardModel) => {
      if (!a.sort || !a.sort.dashboard ) {
        if (!b.sort || !b.sort.dashboard) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 1;
        }
      } else {
        if (!b.sort || !b.sort.dashboard) {
          return -1;
        } else {
          if (a.sort.dashboard[this.id] < b.sort.dashboard[this.id]) {
            return -1;
          } else {
            return 1;
          }
        }
      }
      return 0;
    };
    this.reportCardList.items = this.reportCardList.items.sort(compare);
  }

  public async updateSort() {
    const data: any = [];
    for (const card of this.reportCardList.items) {
      data.push({id: card.id});
    }
    const params: any = {cards: data};
    const res = await put(`/dashboards/${this.id}/options`, params );
  }

  public async updateCardOption() {
    const data: any = [];
    for (const card of this.reportCardList.items) {
      data.push({
        id: card.id,
        size: {
          height: card.cardHeightSize,
          width: card.cardWidthSize,
        },
      });
    }
    const params: any = {cards: data};
    const res = await put(`/dashboards/${this.id}/options`, params );
  }
  public async moveCardPosition(cardId: number, isMoveLeft: boolean = true) {
    let position: number = 0;
    let index: number = 0;
    const newCardList: ReportCardList = new ReportCardList();
    for (const card of this.reportCardList.items) {
      newCardList.add(card);
    }

    for (const card of this.reportCardList.items) {
      if (card.id === cardId) {
        position = index;
        break;
      }
      index++;
    }
    let firstCard: ReportCardModel;
    let secondCard: ReportCardModel;
    if (isMoveLeft) {
      if (position === 0) {
        return;
      } else {
        firstCard = this.reportCardList.items[position - 1];
        secondCard = this.reportCardList.items[position];
        newCardList.items[position - 1] = secondCard;
        newCardList.items[position] = firstCard;
      }
    } else {
      if (position === this.reportCardList.size()) {
        return;
      } else {
        firstCard = this.reportCardList.items[position];
        secondCard = this.reportCardList.items[position + 1];
        newCardList.items[position] = secondCard;
        newCardList.items[position + 1] = firstCard;
      }
    }
    this.reportCardList = newCardList;
    this.updateSort();
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.merchantId = data.merchantId;
  }
}
