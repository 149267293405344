
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NoteList } from '@/collections/notes';
import { NoteModel } from '@/models/note';
import { StoreModel } from '@/models/store';

@Component({
  components: {},
})
export default class StoreNotes extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public dialog: boolean = false;
  public newNote: NoteModel = new NoteModel();
  public notes: NoteList = new NoteList();
  public savingNote: boolean = false;

  public async created() {
    await this.fetchNotes();
  }

  public async fetchNotes() {
    this.notes = new NoteList();
    await this.notes.fetch(this.store.id);
  }

  public async createNewNote() {
    this.savingNote = true;
    this.newNote.merchantId = this.store.id;
    await this.newNote.save();
    this.newNote = new NoteModel();
    await this.fetchNotes();
    this.savingNote = false;
  }

  public detectLink(text: string) {
    return text.replace(
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
      '<a href="$1" target="_blank">$1</a>',
    );
  }

  public convertDateStr(dateStr: string) {
    if (!dateStr) {
      return '';
    }
    const date = new Date(dateStr);
    const timezoneOffset = 7 * 60;
    const adjustedDate = new Date(date.getTime() + timezoneOffset * 60 * 1000);
    const padZero = (num: number) => (num < 10 ? '0' : '') + num;
    return `${adjustedDate.getUTCFullYear()}-${padZero(adjustedDate.getUTCMonth() + 1)}-${padZero(adjustedDate.getUTCDate())} ${padZero(adjustedDate.getUTCHours())}:${padZero(adjustedDate.getUTCMinutes())}:${padZero(adjustedDate.getUTCSeconds())}`;
  }
}
