import { get, post, put, del } from '@/services/http';

export class RuleModel {
  public id: number;
  public code: string;
  public name: string;
  public type: string;
  public value: string|number;
  public initValue: Array<string|number>;
  public status: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.type = data.type;
    this.value = data.value;
    this.initValue = data.initValue || [];
    this.status = 'ACTIVE';
  }

  public async fetch() {
    const res: any = await get(`/rules/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    if (this.type === 'lock') {
      this.initValue = ['locked', 'unlocked'];
    }
    const res: any = await post('/rules', {
      name: this.name,
      code: this.code,
      type: this.type,
      defaultValue: this.value,
      initValue: this.initValue,
    });
    this.mapData(res.data);
  }

  public async update() {
    const res: any = await put(`/rules/${this.id}`, {
      name: this.name,
      code: this.code,
      type: this.type,
      defaultValue: this.value,
      initValue: this.initValue,
    });
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.type = data.type;
    this.value = data.defaultValue || data.value;
    if (this.type === 'number') {
      this.value = Number(this.value);
    }
    this.initValue = data.initValue;
    this.status = 'ACTIVE';
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
