
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { FilterColumnList } from '@/collections/filter_columns';
import { FilterColumnModel } from '@/models/filter_column';

@Component({
  components: {
  },
})
export default class FilterColumn extends Vue {
  @Prop({ default: null })
  public value!: FilterColumnList;
  public columnFilterMenu: boolean = false;
  @Prop({ default: null })
  public initFilters!: FilterColumnList;
  public filters: FilterColumnList = new FilterColumnList();
  public errorMessage: string = '';
  public operators: any = [
    { name: 'equal', value: 'eq' },
    { name: 'equal (case-insensitive)', value: 'eqi' },
    { name: 'not equal', value: 'ne' },
    { name: 'is lower than', value: 'lt' },
    { name: 'is lower than or equal to', value: 'lte' },
    { name: 'is greater than', value: 'gt' },
    { name: 'is greater than or equal to', value: 'gte' },
    { name: 'contains', value: 'contains' },
    { name: 'not contains', value: 'notContains' },
    { name: 'is blank', value: 'null' },
    { name: 'is not blank', value: 'notNull' },
  ];
  public reportColumns: any = [
    { name: 'Day', code: 'Posting Date[Date Name]', dataType: 'date' },
    { name: 'Orders Count', code: '[Order Count]', dataType: 'decimal'},
    { name: 'Units Sold', code: '[Quantity Sold]', dataType: 'decimal'},
    { name: 'Net Units Sold', code: '[net_quantity_sold]', dataType: 'decimal'},
    { name: 'Gross Sales ($)', code: '[Gross Amount]', dataType: 'currency'},
    { name: 'Discount Amount ($)', code: '[Discount]', dataType: 'currency'},
    { name: 'Return Amount ($)', code: '[Return Amount]', dataType: 'currency'},
    { name: 'Net Sales ($)', code: '[Net Sales]', dataType: 'currency'},
    { name: 'Shipping Fee ($)', code: '[Shipping Amount]', dataType: 'currency'},
    { name: 'Tax Amount ($)', code: '[Tax Amount]', dataType: 'currency'},
    { name: 'Total Sales ($)', code: '[Gross Sales]', dataType: 'currency'},
    { name: 'COGs ($)', code: '[cogs]', dataType: 'currency'},
    { name: 'Gross Profit ($)', code: '[Profit Margin]', dataType: 'currency'},
    { name: 'Profit Margin %', code: '[Profit Margin %]', dataType: 'percent'},
    { name: 'AOV ($)', code: '[avg_order_value]', dataType: 'currency'},
    { name: 'Customers Count', code: '[total_customers]', dataType: 'decimal'},
    { name: 'Tip Amount ($)', code: '[Tip Amount]', dataType: 'currency'},
    { name: 'Product', code: 'Item[Item Description]', dataType: 'text'},
    { name: 'Variant', code: 'variant', dataType: 'text'},
    { name: 'SKU', code: 'product_sku', dataType: 'text'},
    { name: 'Barcode', code: 'product_barcode', dataType: 'text'},
    { name: 'Vendor', code: 'Vendor Name', dataType: 'text'},
    { name: 'Product Status', code: 'product_status', dataType: 'text'},
    { name: 'Product Tags', code: 'product_tags', dataType: 'text'},
    { name: 'Store Location', code: 'Location[Location]', dataType: 'text'},
    { name: 'Inventory qty', code: '[Actual Inventory Quantity]', dataType: 'decimal'},
    { name: 'Unit cost ($)', code: '[unit_cost]', dataType: 'currency'},
    { name: 'Inventory Value ($)', code: '[Actual Inventory Cost]', dataType: 'currency'},
    { name: 'Selling price ($)', code: '[Selling Price]', dataType: 'currency'},
    { name: 'Retail value ($)', code: '[Retail Value]', dataType: 'currency'},
    { name: 'Original Price ($)', code: '[original_price]', dataType: 'currency'},
    { name: 'Product type', code: 'product_type', dataType: 'text'},
    { name: 'Sell Through Rate', code: '[sell_through_rate]', dataType: 'percent'},
    { name: 'Return Rate (Return_Quantity / Units_Sold)' , code: ' [refund_order_rate]' , dataType: ' percent' },
    { name: 'Return Quantity' , code: '[Return Quantity]' , dataType: ' decimal' },
    { name: 'Average Daily Qty Sold' , code: '[avg_daily_qty_sold]' , dataType: ' decimal' },
    { name: 'Out of stock days' , code: '[oos_days]' , dataType: ' number' },
    { name: 'Zero Sales Days' , code: '[Slow Days]' , dataType: ' number' },
    { name: 'Last Order Date' , code: ' Last Sales Date' , dataType: ' date' },
    { name: 'Aged Days' , code: '[Aged Days]' , dataType: ' number' },
    { name: 'Last Received Date' , code: 'received_date' , dataType: ' date' },
    { name: 'Units Sold last 7 days' , code: '[units_sold_last_1w]' , dataType: ' decimal' },
    { name: 'Units Sold last 14 days' , code: '[units_sold_last_2w]' , dataType: ' decimal' },
    { name: 'Units Sold last 21 days' , code: '[units_sold_last_3w]' , dataType: ' decimal' },
    { name: 'Units Sold last 28 days' , code: '[units_sold_last_4w]' , dataType: ' decimal' },
    { name: 'Units Sold last 30 days' , code: '[units_sold_last_30d]' , dataType: ' decimal' },
    { name: 'Units Sold last 60 days' , code: '[units_sold_last_60d]' , dataType: ' decimal' },
    { name: 'Units Sold last 90 days' , code: '[units_sold_last_90d]' , dataType: ' decimal' },    
    { name: 'Qty replenish for 28 days' , code: '[qty_replenish_4w]' , dataType: ' decimal' },
    { name: 'Qty replenish for 21 days' , code: '[qty_replenish_3w]' , dataType: ' decimal' },
    { name: 'Qty replenish for 14 days' , code: '[qty_replenish_2w]' , dataType: ' decimal' },
    { name: 'Qty replenish for 7 days' , code: '[qty_replenish_1w]' , dataType: ' decimal' },
    { name: 'Discount %' , code: ' [Discount %]' , dataType: ' percent' },
    { name: 'Month', code: 'Posting Date[Year Month Name]', dataType: 'text'},
    { name: 'Total Orders', code: '[Order Count]', dataType: 'decimal'},
    { name: 'AOV ($)', code: '[avg_order_value]', dataType: 'currency'},
    { name: 'Collection', code: 'Item[Item Category]', dataType: 'text'},
    { name: 'Product Variant', code: 'Variant Name', dataType: 'text'},
    { name: 'Sales Channel Online & Offline', code: 'sales_channel', dataType: 'text'},
    { name: 'Product Tag', code: 'product_tag', dataType: 'text'},
    { name: 'Product Collections', code: 'product_collections', dataType: 'text'},
    { name: 'Sales Channel', code: 'sales_channel_name', dataType: 'text'},
    { name: 'Order Value Range', code: 'order_value_range', dataType: 'text'},
    { name: 'Continue selling when out of stock', code: 'continue_selling_out_stock', dataType: 'text'},
    { name: 'Inventory Tracking', code: 'inventoty_tracking', dataType: 'text'},
    { name: 'Fulfillment Status', code: 'fulfillment_status', dataType: 'text'},
    { name: 'Order Status', code: 'order_status', dataType: 'text'},
    { name: 'Fulfillable Quantity', code: '[fulfillable_quantity]', dataType: 'number'},
    { name: 'Requires Shipping', code: 'requires_shipping', dataType: 'text'},
    { name: 'Inventory Ready To Fulfill', code: 'item_ready_to_fulfill', dataType: 'text'},
    { name: 'Days of Inventory Remaining', code: '[stock_days]', dataType: 'number'},
    { name: 'Weeks of remaining Inventory', code: '[stock_weeks]', dataType: 'number'},
    { name: 'Sales percent rank', code: '[sales_percent_rank]', dataType: 'number'},
    { name: 'Inventory quantity rank', code: '[inventory_percent_rank]', dataType: 'number'},
    { name: 'Sales Amount percent rank', code: '[sales_amount_percent_rank]', dataType: 'number'},
    { name: 'Profit Margin rank', code: '[profit_percent_rank]', dataType: 'number'},
    { name: 'Gross Profit Percent Rank', code: '[profit_amount_percent_rank]', dataType: 'number'},
    { name: 'Order Canceled', code: 'order_canceled', dataType: 'text'},
  ];
  public created() {
    this.assignInitFilter();
  }

  public assignInitFilter() {
    if (this.initFilters) {
      const filters: FilterColumnList = new FilterColumnList();
      let data: any;
      for (const item of this.initFilters.items) {
        const values: string[] = [];
        for (const v of item.value) {
          values.push(v);
        }
        data = { name: item.name, code: item.code, value: values, operator: item.operator, dataType: item.dataType };
        filters.add(new FilterColumnModel(data));
      }
      this.filters = filters;
    }
    if (this.filters.items.length === 0) {
      this.addBlankFilter();
    }
  }

  public get filtersCount() {
    let count: number = 0;
    if (this.filters) {
      for (const item of this.filters.items) {
        if (item.code) {
          count = count + 1;
        }
      }
      if (count > 0) {
        return ' (' + count + ' actives) ';
      }

    }
    return '';
  }

  public deleleFilter(index: number) {
    this.filters.items.splice(index, 1);
    if (this.filters.items.length === 0) {
      this.addBlankFilter();
    }
  }

  public clearFilter() {
    this.errorMessage = '';
    this.columnFilterMenu = false;
    const selectedFilters: FilterColumnList = new FilterColumnList();
    this.$emit('input', selectedFilters);
    this.$emit('not-reset-filter-column', false);
    const newFilters: FilterColumnList = new FilterColumnList();
    const blankFilter = new FilterColumnModel({ name: '', code: '', operator: 'eq', value: [], dataType: 'text' });
    newFilters.add(blankFilter);
    this.filters = newFilters;
  }

  public saveFilter() {
    const selectedFilters: FilterColumnList = new FilterColumnList();
    let data: any;
    for (const item of this.filters.items) {
      if (item.code && item.value.length > 0 && item.operator) {
        const filterName = this.getFilterColumnName(item.code);
        const filterDataType = this.getFilterColumnDataType(item.code);
        const values: string[] = [];
        for (const v of item.value) {
          values.push(v);
        }
        data = {name: filterName, code: item.code, value: values, operator: item.operator, dataType: filterDataType};
        selectedFilters.add(new FilterColumnModel(data));
      }
    }
    if (selectedFilters.items.length > 0) {
      this.$emit('input', selectedFilters);
      this.$emit('not-reset-filter-column', false);
      this.errorMessage = '';
      this.columnFilterMenu = false;
    } else {
      this.errorMessage = 'Please filled in all the values';
    }

  }

  public getFilterColumnName(code: string) {
    for (const column of this.reportColumns) {
      if (column.code === code) {
        return column.name;
      }
    }
    return code;
  }

  public getFilterColumnDataType(code: string) {
    for (const column of this.reportColumns) {
      if (column.code === code) {
        return column.dataType;
      }
    }
    return 'text';
  }

  public removeFilterValue(index: number , item: string) {
    const newValues = this.filters.items[index].value;
    if (newValues && newValues.length > 0) {
      const itemIndex = newValues.indexOf(item);
      if (itemIndex !== -1) {
        newValues.splice(itemIndex, 1);
      }
      this.filters.items[index].value = newValues;
    }
  }

  public addBlankFilter() {
    this.errorMessage = '';
    for (const item of this.filters.items) {
      if (!item.code) {
        return;
      }
    }
    const blankFilter = new FilterColumnModel({ name: '', code: '', operator: 'eq', value: [], dataType: 'text' });
    this.filters.items.push(blankFilter);
  }

  @Watch('initFilters', { immediate: true, deep: true })
  private async onInitFiltersChange(newVal: any) {
    if (this.initFilters) {
      this.assignInitFilter();
    }
  }
}
