
import { Component, Vue } from 'vue-property-decorator';
import { StoreList } from '@/collections/stores';
import { StoreModel } from '@/models/store';

@Component({
  components: {
  },
})
export default class PaidStores extends Vue {
  public isLoading: boolean = true;
  public stores: StoreList = new StoreList();

  public async created() {
    await this.fetchData();
  }

  public async fetchData() {
    this.isLoading = true;
    this.stores = new StoreList();
    await this.stores.getPaidStores();
    this.isLoading = false;
  }
}
