import { get } from '@/services/http';

export class FilterColumnModel {
  public name: string;
  public code: string;
  public value: string[];
  public operator: string;
  public dataType: string;


  constructor(data: any = {}) {
    this.name = data.name;
    this.code = data.code;
    this.value = data.value;
    this.operator = data.operator;
    this.dataType = data.dataType;
  }

  public mapData(data: any = {}) {
    this.name = data.name;
    this.code = data.code;
    this.value = data.value;
    this.operator = data.operator;
    this.dataType = data.dataType;
  }
}
