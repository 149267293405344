import { get, post } from '@/services/http';
import { RuleModel } from '@/models/rule';
import List from './list';

export class RuleList extends List<RuleModel> {
  public async fetch() {
    const res: any = await get(`/rules`, {});
    const rules = res.data || [];
    this.mapData(rules);
  }

  public mapData(rules: RuleModel[]) {
    for (const s of rules) {
      const rule = new RuleModel();
      rule.mapData(s);
      this.add(rule);
    }
  }
}
