import { get, post, put, del } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { TagModel } from '@/models/tag';
import { TagList } from '@/collections/tags';
import { FilterColumnModel } from '@/models/filter_column';
import { FilterColumnList } from '@/collections/filter_columns';

interface SortOrder {
  tag: any;
}

export class TemplateReportModel {
  public id: number;
  public name: string;
  public measure: MeasureModel;
  public dimension: DimensionModel;
  public analyticsType: AnalyticsTypeModel;
  public tags: TagList;
  public sort: number;
  public sortOrder: SortOrder;
  public image: string;
  public filterTimerange?: string[];
  public predefinedTimeRange?: string;
  public isLocked?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public instruction?: string;
  public hasChart?: boolean;
  public filterColumns: FilterColumnList;
  public reportColumns: any = [];
  public pivotOption: string;
  public terms?: string[];

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
    this.analyticsType = new AnalyticsTypeModel();
    this.filterColumns = new FilterColumnList();
    this.reportColumns = [];
    this.tags = new TagList();
    this.sort = data.sort;
    this.sortOrder = data.sortOrder;
    this.isLocked = data.isLocked;
    this.image = data.image;
    this.instruction = data.instruction;
    this.hasChart = data.hasChart;
    this.pivotOption = data.pivotOption;
  }

  public async fetch() {
    const res: any = await get(`/template_reports/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    const res: any = await post('/template_reports', {
      measure: this.measure.code,
      name: this.name,
      dimension: (this.dimension) ? this.dimension.code : null,
      analyticsType: (this.analyticsType) ? this.analyticsType.code : null,
      tagIds: this.tags.items.map((item) => item.id).join(','),
      startTime: (this.filterTimerange && this.filterTimerange.length > 1) ? this.filterTimerange[0] : null,
      endTime: (this.filterTimerange && this.filterTimerange.length > 1) ? this.filterTimerange[1] : null,
      number: this.sort,
      predefinedTimeRange: this.predefinedTimeRange,
      isLocked: this.isLocked,
      image: this.image,
      instruction: this.instruction,
      hasChart: this.hasChart,
      filterColumn: this.exportFilterColumns(),
      pivotOption: this.pivotOption,
      terms: this.terms ? this.terms.join(',') : null,
      reportColumns: this.reportColumns,
    });
    this.mapData(res.data);
  }

  public async update() {
    const data = {
      measure: this.measure.code,
      name: this.name,
      dimension: (this.dimension) ? this.dimension.code : null,
      analyticsType: (this.analyticsType) ? this.analyticsType.code : null,
      tagIds: this.tags.items.map((item) => item.id).join(','),
      startTime: (this.filterTimerange && this.filterTimerange.length > 1) ? this.filterTimerange[0] : null,
      endTime: (this.filterTimerange && this.filterTimerange.length > 1) ? this.filterTimerange[1] : null,
      number: this.sort,
      predefinedTimeRange: this.predefinedTimeRange,
      isLocked: this.isLocked,
      image: this.image,
      instruction: this.instruction,
      hasChart: this.hasChart,
      filterColumn: this.exportFilterColumns(),
      pivotOption: this.pivotOption,
      terms: this.terms ? this.terms.join(',') : null,
      reportColumns: this.reportColumns,
    };
    const res: any = await put(`/template_reports/${this.id}`, data);
  }

  public async updateLockStatus() {
    const data = {
      isLocked: this.isLocked,
    };
    const res: any = await put(`/template_reports/${this.id}`, data);
  }

  public async del() {
    const res: any = await del(`/template_reports/${this.id}`);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel({ code: data.measureCode });
    this.dimension = new DimensionModel({ code: data.dimensionCode });
    this.analyticsType = new AnalyticsTypeModel({ code: data.analyticsTypeCode });
    if ( data.filterColumn) {
      this.importFilterColumns(data.filterColumn);
    }
    if (data.tagIds) {
      for (const tagId of data.tagIds.split(',')) {
        this.tags.add(new TagModel({ id: tagId }));
      }
    }
    if (data.startTime && data.endTime) {
      this.filterTimerange = [data.startTime || '', data.endTime || ''];
    }
    if (data.reportColumns) {
      this.reportColumns = data.reportColumns;
    }
    this.sort = data.number;
    this.sortOrder = data.sortOrder;
    this.predefinedTimeRange = data.predefinedTimeRange;
    this.isLocked = data.isLocked || 'AVAILABLE';
    this.image = data.image;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.instruction = data.instruction;
    this.hasChart = data.hasChart;
    this.pivotOption = data.pivotOption;
    this.terms = data.terms ? data.terms.split(',') : null;

  }

  private importFilterColumns(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data)) {
      return;
    }
    this.filterColumns = new FilterColumnList();
    for (const values of data) {
      const filterColumn: FilterColumnModel = new FilterColumnModel();
      filterColumn.mapData(values);
      this.filterColumns.add(filterColumn);
    }
  }

  private exportFilterColumns() {
    const data = [];
    for (const filterColumn of this.filterColumns.items) {
      data.push({
        name: filterColumn.name,
        code: filterColumn.code,
        value: filterColumn.value,
        operator: filterColumn.operator,
        dataType: filterColumn.dataType,
      });
    }
    return data;
  }
}
