import { get, post, put, del } from '@/services/http';

export class StoreModel {
  public id: number;
  public name: string;
  public enableAthena: boolean;
  public email: string;
  public timezone: string;
  public phone: string;
  public contactName: string;
  public etlScheduler: string;
  public isLockedReports: string;
  public isLockedDownload: string;
  public createdAt?: string;
  public updatedAt?: string;
  public trialEndAt?: string;
  public activatedAt?: string;
  public planName?: string;
  public planPrice?: number;
  public planDiscount?: number;
  public planInterval?: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.enableAthena = data.enableAthena;
    this.email = data.email;
    this.timezone = data.timezone;
    this.phone = data.phone;
    this.contactName = data.contactName;
    this.etlScheduler = 'daily';
    this.isLockedReports = 'available';
    this.isLockedDownload = 'locked';
  }

  public async fetch() {
    const res: any = await get(`/stores/${this.id}`);
    this.mapData(res.data);
  }

  public async generateLink() {
    const res: any = await post(`/hades/generate`, { id: this.id });
    return res.token;
  }

  public async upgradeEtlSchedule() {
    await put(`/stores/${this.id}`);
    this.etlScheduler = 'hourly_confirming';
  }

  public async unlockReports() {
    if (this.isLockedReports === 'available') {
      return;
    }
    await put(`/stores/${this.id}/unlock_reports`);
    this.isLockedReports = 'available';
  }

  public async unlockDownload() {
    if (this.isLockedDownload === 'available') {
      return;
    }
    await put(`/stores/${this.id}/unlock_download`);
    this.isLockedDownload = 'available';
  }

  public async extendTrial(day: number) {
    return await put(
      `/user_packages`,
      { id: this.id, extend_day: day },
    ).then((response: any) => {
      return {
        isError: false,
        message: response.message,
        data: response.data.confirmationUrl,
      };
    }).catch((err: any) => {
      return {
        isError: true,
        message: err.response.message,
      };
    });
  }

  public async bugReport() {
    const res: any = await put(`/stores/${this.id}/bug_report`);
    return res;
  }

  public async clearCache() {
    const res: any = await del(`/query_cache/clean?merchant_id=${this.id}`);
    return res;
  }

  public async reloadWebhook() {
    const res: any = await post(`/webhooks/reload`, { merchantId: this.id });
    return res;
  }

  public async testInvetory() {
    const res: any = await get(`/auto_test/inventory_v2?merchant_id=${this.id}&period=3`);
    return res;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.shop_name;
    this.enableAthena = data.enable_athena;
    if (data.merchant && data.merchant.config) {
      this.email = data.merchant.config.contactEmail;
      this.timezone = data.merchant.config.timezone;
      this.phone = data.merchant.config.phone;
      this.contactName = data.merchant.config.contactName;
      this.etlScheduler = data.merchant.schedule_etl || 'daily';
      this.isLockedReports = data.merchant.is_locked_reports || 'available';
      this.isLockedDownload = data.merchant.is_locked_download || 'locked';
    }
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.trialEndAt = data.trialEndAt;
    this.activatedAt = data.activatedAt;
    this.planName = data.planName;
    this.planPrice = data.planPrice;
    this.planDiscount = data.planDiscount;
    this.planInterval = data.planInterval;

  }
}
