
import { Component, Vue, Watch } from 'vue-property-decorator';
import LogoBar from '@/components/LogoBar.vue';

@Component({
  components: {
    LogoBar,
  },
})
export default class SideBar extends Vue {
  public sidebarMenu: boolean = true;
  public isMini: boolean = true;
  public items: Array<{ title: string, href: string, icon: string }> = [
    { title: 'Home', href: '/', icon: 'mdi-home-outline' },
    { title: 'Control Panel', href: '/control-panel', icon: 'mdi-controller' },
    { title: 'Template Report', href: '/template_reports', icon: 'mdi-chart-bar' },
    { title: 'Tag', href: '/tags', icon: 'mdi-tag' },
    { title: 'Tag Type', href: '/tag_types', icon: 'mdi-tag-text-outline' },
    { title: 'Stores', href: '/stores', icon: 'mdi-store' },
    { title: 'Passed Trial Stores', href: '/passed_trial_stores', icon: 'mdi-store' },
    { title: 'Active Trial Stores', href: '/active_trial_stores', icon: 'mdi-store' },
    { title: 'Store Metadata', href: '/store_metadata', icon: 'mdi-store-search-outline' },
    { title: 'User Dashboards', href: '/user_dashboards', icon: 'mdi-chart-line' },
    { title: 'Custom Reports', href: '/custom_reports', icon: 'mdi-table' },
    { title: 'Billing Packages', href: '/billing_packages', icon: 'mdi-cash-clock' },
    { title: 'Plugins', href: '/plugins', icon: 'mdi-view-module' },
    { title: 'Rules', href: '/rules', icon: 'mdi-card-bulleted-outline' },
    { title: 'Resources', href: '/resource', icon: 'mdi-semantic-web' },
    { title: 'Discount', href: '/discounts', icon: 'mdi-sale' },
    { title: 'FAQ', href: '/faqs', icon: 'mdi-frequently-asked-questions' },
    { title: 'Bugs', href: '/bugs', icon: 'mdi-bug' },
    // suggest an icon for this
    { title: 'Default table versions', href: '/default_table_versions',
      icon: 'mdi-format-list-numbered' },
  ];

  public created() {
    this.sidebarMenu = !this.$vuetify.breakpoint.smAndDown;
    this.isMini = this.$vuetify.breakpoint.smAndDown;
  }

  @Watch('$vuetify.breakpoint.smAndDown')
  public onMini(value: any, oldValue: any) {
    this.sidebarMenu = !this.$vuetify.breakpoint.smAndDown;
    this.isMini = this.$vuetify.breakpoint.smAndDown;
  }
}

