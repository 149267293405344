
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DiscountModel } from '@/models/discount';

@Component({
  components: {
  },
})
export default class DiscountNewPage extends Vue {
  public isLoading: boolean = true;
  public discount: DiscountModel = new DiscountModel();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    // skipped
  }

  public async createDiscount() {
    await this.discount.save();
    await this.$router.push('/discounts');
  }
}
