import { get, put } from '@/services/http';

interface Features {
  notification: boolean;
}

interface Data {
  threeMonths: boolean;
  allHistory: boolean;
}

export class PermissionModel {
  public id?: number;
  public features: Features;
  public data: Data;
  public merchantId?: string;
  public status: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.features = {
      notification: false,
    };
    this.data = {
      threeMonths: true,
      allHistory: false,
    };
    this.status = data.status;
  }

  public async fetch() {
    const res: any = await get(`/permissions/${this.id}`);
    this.mapData(res.data);
  }

  public async update() {
    const res: any = await put(`/permissions/${this.id}`, {
      features: this.features,
      data: this.data,
    });
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.features = (data.data.features) ? data.data.features : { notification: false };
    this.data = (data.data.data) ? data.data.data : { threeMonths: true, allHistory: false };
    this.merchantId = data.merchantId;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
