import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import SignInPage from '@/views/SignInPage.vue';
import TemplateReports from '@/views/TemplateReports.vue';
import TemplateReportSortPage from '@/views/TemplateReportSortPage.vue';
import TemplateReportNewPage from '@/views/TemplateReportNewPage.vue';
import TemplateReportEditPage from '@/views/TemplateReportEditPage.vue';
import Faqs from '@/views/Faqs.vue';
import FaqNewPage from '@/views/FaqNewPage.vue';
import FaqEditPage from '@/views/FaqEditPage.vue';
import Tags from '@/views/Tags.vue';
import TagNewPage from '@/views/TagNewPage.vue';
import TagEditPage from '@/views/TagEditPage.vue';
import TagTypes from '@/views/TagTypes.vue';
import TagTypeNewPage from '@/views/TagTypeNewPage.vue';
import TagTypeEditPage from '@/views/TagTypeEditPage.vue';
import Permissions from '@/views/Permissions.vue';
import PermissionEditPage from '@/views/PermissionEditPage.vue';
import Stores from '@/views/Stores.vue';
import PaidStores from '@/views/PaidStores.vue';
import PassedTrialStores from '@/views/PassedTrialStores.vue';
import ActiveTrialStores from '@/views/ActiveTrialStores.vue';
import Store from '@/views/Store.vue';
import DefaultMetrics from '@/views/DefaultMetrics.vue';
import DefaultMetricNewPage from '@/views/DefaultMetricNewPage.vue';
import BillingPackages from '@/views/BillingPackages.vue';
import BillingPackage from '@/views/BillingPackage.vue';
import BillingPackageNewPage from '@/views/BillingPackageNewPage.vue';
import Rules from '@/views/Rules.vue';
import RuleNewPage from '@/views/RuleNewPage.vue';
import RuleEditPage from '@/views/RuleEditPage.vue';
import UserDashboards from '@/views/UserDashboards.vue';
import CustomReports from '@/views/CustomReports.vue';
import Discounts from '@/views/Discounts.vue';
import DiscountNewPage from '@/views/DiscountNewPage.vue';
import DiscountEditPage from '@/views/DiscountEditPage.vue';
import PluginList from '@/views/Plugins.vue';
import ControlPanel from '@/views/ControlPanel.vue';
import PluginEdit from '@/views/PluginEdit.vue';
import ResourcePage from '@/views/ResourcePage.vue';
import ResourceNewPage from '@/views/ResourceNewPage.vue';
import StoreMetadataPage from '@/views/StoreMetadataPage.vue';
import StoreMetadataDetailPage from '@/views/StoreMetadataDetailPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignInPage,
    meta: {
      title: 'Sign in',
    },
  },
  {
    path: '/template_reports',
    name: 'template_reports',
    component: TemplateReports,
    meta: {
      title: 'Template reports',
    },
  },
  {
    path: '/template_reports/sort',
    name: 'template_report_sort',
    component: TemplateReportSortPage,
    meta: {
      title: 'Sort template reports',
    },
  },
  {
    path: '/template_reports/new',
    name: 'template_reports_new',
    component: TemplateReportEditPage,
    meta: {
      title: 'New template report',
    },
  },
  {
    path: '/template_reports/:id',
    name: 'template_reports_edit',
    component: TemplateReportEditPage,
    meta: {
      title: 'Edit template report #{{ id }}',
    },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: Faqs,
    meta: {
      title: 'FAQs',
    },
  },
  {
    path: '/faqs/new',
    name: 'faqs_new',
    component: FaqNewPage,
    meta: {
      title: 'New FAQ',
    },
  },
  {
    path: '/faqs/:id',
    name: 'faqs_edit',
    component: FaqEditPage,
    meta: {
      title: 'Edit FAQ #{{ id }}',
    },
  },
  {
    path: '/tags',
    name: 'tags',
    component: Tags,
    meta: {
      title: 'Tags',
    },
  },
  {
    path: '/tags/new',
    name: 'tags_new',
    component: TagNewPage,
    meta: {
      title: 'New tag',
    },
  },
  {
    path: '/tags/:id',
    name: 'tags_edit',
    component: TagEditPage,
    meta: {
      title: 'Edit tag #{{ id }}',
    },
  },
  {
    path: '/tag_types',
    name: 'tag_types',
    component: TagTypes,
    meta: {
      title: 'Tag types',
    },
  },
  {
    path: '/tag_types/new',
    name: 'tag_types_new',
    component: TagTypeNewPage,
    meta: {
      title: 'New tag type',
    },
  },
  {
    path: '/tag_types/:id',
    name: 'tag_types_edit',
    component: TagTypeEditPage,
    meta: {
      title: 'Edit tag type #{{ id }}',
    },
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: Permissions,
    meta: {
      title: 'Permissions',
    },
  },
  {
    path: '/permissions/:id',
    name: 'permission_edit',
    component: PermissionEditPage,
    meta: {
      title: 'Edit permission #{{ id }}',
    },
  },
  {
    path: '/stores',
    name: 'store_list',
    component: Stores,
    meta: {
      title: 'Stores',
    },
  },
  {
    path: '/paid_stores',
    name: 'paid_store_list',
    component: PaidStores,
    meta: {
      title: 'Paid stores',
    },
  },
  {
    path: '/passed_trial_stores',
    name: 'passed_trial_store_list',
    component: PassedTrialStores,
    meta: {
      title: 'Passed trial stores',
    },
  },
  {
    path: '/active_trial_stores',
    name: 'active_trial_store_list',
    component: ActiveTrialStores,
    meta: {
      title: 'Active trial stores',
    },
  },
  {
    path: '/stores/:id',
    name: 'store',
    component: Store,
    meta: {
      title: 'Store #{{ id }}',
    },
  },
  {
    path: '/default-metrics',
    name: 'default_metrics_list',
    component: DefaultMetrics,
    meta: {
      title: 'Default metrics',
    },
  },
  {
    path: '/default-metrics/new',
    name: 'default_metrics_new',
    component: DefaultMetricNewPage,
    meta: {
      title: 'New default metric',
    },
  },
  {
    path: '/billing_packages',
    name: 'billing_packages_list',
    component: BillingPackages,
    meta: {
      title: 'Billing packages',
    },
  },
  {
    path: '/billing_packages/new',
    name: 'billing_packages_new',
    component: BillingPackageNewPage,
    meta: {
      title: 'New billing package',
    },
  },
  {
    path: '/billing_packages/:id',
    name: 'billing_package',
    component: BillingPackage,
    meta: {
      title: 'Billing package #{{ id }}',
    },
  },
  {
    path: '/rules',
    name: 'rules_list',
    component: Rules,
    meta: {
      title: 'Rules',
    },
  },
  {
    path: '/rules/new',
    name: 'rules_new',
    component: RuleNewPage,
    meta: {
      title: 'New rule',
    },
  },
  {
    path: '/rules/:id',
    name: 'rules_edit',
    component: RuleEditPage,
    meta: {
      title: 'Edit rule #{{ id }}',
    },
  },
  {
    path: '/user_dashboards',
    name: 'userDashboards',
    component: UserDashboards,
    meta: {
      title: 'User dashboards',
    },
  },
  {
    path: '/custom_reports',
    name: 'customReports',
    component: CustomReports,
    meta: {
      title: 'Custom reports',
    },
  },
  {
    path: '/discounts',
    name: 'discounts',
    component: Discounts,
    meta: {
      title: 'Discounts',
    },
  },
  {
    path: '/discounts/new',
    name: 'DiscountNewPage',
    component: DiscountNewPage,
    meta: {
      title: 'New discount',
    },
  },
  {
    path: '/discounts/:id',
    name: 'DiscountEditPage',
    component: DiscountEditPage,
    meta: {
      title: 'Edit discount #{{ id }}',
    },
  },
  {
    path: '/control-panel',
    name: 'ControlPanel',
    component: ControlPanel,
    meta: {
      title: 'Control panel',
    },
  },
  {
    path: '/plugins',
    name: 'pluginList',
    component: PluginList,
    meta: {
      title: 'Plugins',
    },
  },
  {
    path: '/plugin/new',
    name: 'plugin_new',
    component: PluginEdit,
    meta: {
      title: 'New plugin',
    },
  },
  {
    path: '/plugin/edit/:id',
    name: 'plugin_edit',
    component: PluginEdit,
    meta: {
      title: 'Edit plugin #{{ id }}',
    },
  },
  {
    path: '/resource',
    name: 'resourcePage',
    component: ResourcePage,
    meta: {
      title: 'Resources',
    },
  },
  {
    path: '/resource/new',
    name: 'resource_new',
    component: ResourceNewPage,
    meta: {
      title: 'New resource',
    },
  },
  {
    path: '/resource/edit/:id',
    name: 'resource_edit',
    component: ResourceNewPage,
    meta: {
      title: 'Edit resource #{{ id }}',
    },
  },
  {
    path: '/store_metadata',
    name: 'storeMetadataPage',
    component: StoreMetadataPage,
    meta: {
      title: 'Store metadata',
    },
  },
  {
    path: '/store_metadata/:id',
    name: 'storeMetadataDetailPage',
    component: StoreMetadataDetailPage,
    meta: {
      title: 'Store metadata #{{ id }}',
    },
  },
  {
    path: '/bugs',
    name: 'bugs',
    component: () => import('@/views/Bugs.vue'),
    meta: {
      title: 'Bugs',
    },
  },
  {
    path: '/bugs/:id',
    name: 'bug',
    component: () => import('@/views/Bug.vue'),
    meta: {
      title: 'Bug #{{ id }}',
      metaTags: [
        {
          name: 'description',
          content: 'The bug page for {{ id }}',
        },
        {
          property: 'og:description',
          content: 'The bug page for {{ id }}',
        },
      ],
    },
  },
  {
    path: '/default_table_versions',
    name: 'default_table_versions',
    component: () => import('@/views/DefaultTableVersions.vue'),
    meta: {
      title: 'Default table versions',
    },
  },
  {
    path: '/default_table_versions/new',
    name: 'default_table_version_new',
    component: () => import('@/views/DefaultTableVersionNewPage.vue'),
    meta: {
      title: 'New default table version',
    },
  },
  {
    path: '/default_table_versions/:name',
    name: 'default_table_version',
    component: () => import('@/views/DefaultTableVersion.vue'),
    meta: {
      title: 'Default table version {{ name }}',
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  let title = 'Admin system';
  if (to.name && to.name !== 'home') {
    title = to.name.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
  }
  if (to.meta && to.meta.title) {
    title = to.meta.title;
  }
  if (to.params.id) {
    title = title.replace(/{{ id }}/g, to.params.id);
  }
  if (to.params.name) {
    title = title.replace(/{{ name }}/g, to.params.name);
  }
  document.title = `${title} - Hades`;
  next();
});

export default router;
