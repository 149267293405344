
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TagTypeModel } from '@/models/tag_type';

@Component({
  components: {
  },
})
export default class TagTypeNewPage extends Vue {
  public isLoading: boolean = true;
  public tagType: TagTypeModel = new TagTypeModel();
  public messageError: string = '';
  public isError: boolean = false;

  public async createTagType() {
    if (!this.tagType.name || this.tagType.name === '') {
      this.isError = true;
      this.messageError = 'Name is a required param';
      return;
    }

    await this.tagType.save();
    await this.$router.push('/tag_types');
  }
}
