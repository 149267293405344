import { get, post } from '@/services/http';
import { UserPackageModel } from '@/models/user_package';
import List from './list';

export class UserPackageList extends List<UserPackageModel> {
  public merchantId: number;

  constructor(data: any = {}) {
    super();
    this.merchantId = data.merchantId;
  }

  public async fetch() {
    const data: any = {
      merchantId: this.merchantId,
    };
    const res: any = await get(`/user_packages`, data);
    const userPackages = res.data || [];
    for (const s of userPackages) {
      const userPackage: UserPackageModel = new UserPackageModel();
      userPackage.mapData(s);
      this.add(userPackage);
    }
    const compare = (a: UserPackageModel, b: UserPackageModel) => {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    };
    this.items = this.items.sort(compare);
  }
}
