import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import dateFormat from 'dateformat';
import { get, post, del, put } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { FilterColumnModel } from '@/models/filter_column';
import { FilterColumnList } from '@/collections/filter_columns';

import axios from 'axios';

interface SortOrder {
  dashboard: any;
}

export class ReportCardModel {
  public id: number;
  public name: string;
  public measure: MeasureModel;
  public dimension?: DimensionModel;
  public analyticsType?: AnalyticsTypeModel;
  public filterTimerange?: string[];
  public defaultRange: string = '30d';
  public filterColumns: FilterColumnList;
  public createdAt?: string;
  public updatedAt?: string;
  public pivotOption: string = '';
  public reportColumns: any = [];
  public isDetail: boolean = true;
  public typeReport: string = 'TABLE';
  public perPage: number = 5;
  public page: number = 1;
  public cardWidthSize: number = 12;
  public cardHeightSize: number = 1;
  public sort: SortOrder;
  public dashboardIds: number = 0;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel();
    this.filterColumns = new FilterColumnList();
    this.sort = data.sort;
    this.pivotOption = '';
    this.reportColumns = [];
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel({ code: data.measureCode });
    this.dimension = new DimensionModel({ code: data.dimensionCode });
    this.sort = data.sort;
    if (data.analyticsTypeCode) {
      this.analyticsType = new AnalyticsTypeModel({ code: data.analyticsTypeCode });
    }
    if (data.filterColumn) {
      this.importFilterColumns(data.filterColumn);
    }
    this.defaultRange = data.timerange;
    this.pivotOption = data.pivotOption;
    if (data.reportColumns && data.reportColumns.length > 0) {
      this.reportColumns = data.reportColumns;
    }
    if (data.size) {
      this.cardHeightSize = data.size.height;
      this.cardWidthSize = data.size.width;
    }
    if (data.dashboardIds) {
      this.dashboardIds = Number(data.dashboardIds);
    }
    this.typeReport = data.chartType;
  }

  public importFilterColumns(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data.items)) {
      return;
    }
    this.filterColumns = new FilterColumnList();
    for (const values of data.items) {
      const filterColumn: FilterColumnModel = new FilterColumnModel();
      filterColumn.mapData(values);
      this.filterColumns.add(filterColumn);
    }
  }

  public exportFilterColumns() {
    const data = [];
    for (const filterColumn of this.filterColumns.items) {
      data.push({
        name: filterColumn.name,
        code: filterColumn.code,
        value: filterColumn.value,
        operator: filterColumn.operator,
        dataType: filterColumn.dataType,
      });
    }
    return data;
  }

  public async create() {
    const params: any = {
      name: this.name,
      measureCode: this.measure.code,
      analyticsTypeCode: '',
      timerange: this.defaultRange,
      dimensionCode: '',
      filterColumn: '',
      dashboardIds: this.dashboardIds + '',
      size: {
        height: this.cardHeightSize,
        width: this.cardWidthSize,
      },
    };
    if (this.dimension && this.dimension.code !== undefined) {
      params.dimensionCode = this.dimension.code;
    }
    if (this.analyticsType && this.analyticsType.code !== undefined) {
      params.analyticsTypeCode = this.analyticsType.code;
    }
    if (this.filterTimerange && this.filterTimerange[0]) {
      params.startTime = this.filterTimerange[0];
    }
    if (this.filterTimerange && this.filterTimerange[1]) {
      params.endTime = this.filterTimerange[1];
    }
    if (this.filterColumns) {
      params.filterColumn = this.exportFilterColumns();
    }
    const res: any = await post('/cards', params);
    this.mapData(res.data);
  }

  public async removeDashboard() {
    this.dashboardIds = 0;
    await this.update();
  }

  public async update() {
    const params: any = {
      name: this.name,
      measureCode: this.measure.code,
      analyticsTypeCode: '',
      timerange: this.defaultRange,
      dimensionCode: '',
      filterColumn: '',
      dashboardIds: this.dashboardIds + '',
      size: {
        height: this.cardHeightSize,
        width: this.cardWidthSize,
      },
    };
    if (this.dimension && this.dimension.code !== undefined) {
      params.dimensionCode = this.dimension.code;
    }
    if (this.analyticsType && this.analyticsType.code !== undefined) {
      params.analyticsTypeCode = this.analyticsType.code;
    }
    if (this.filterTimerange && this.filterTimerange[0]) {
      params.startTime = this.filterTimerange[0];
    }
    if (this.filterTimerange && this.filterTimerange[1]) {
      params.endTime = this.filterTimerange[1];
    }
    if (this.filterColumns) {
      params.filterColumn = this.filterColumns;
    }
    if (this.reportColumns.length > 0) {
      params.reportColumns = this.reportColumns;
    }
    if (this.typeReport) {
      params.chartType = this.typeReport;
    }
    const res: any = await put(`/cards/${this.id}`, params);
  }

  public async delete(id: number) {
    const res: any = await del(`/cards/${id}`);
  }
  public async fetch() {
    if (!this.id) {
      return;
    }
    const res: any = await get(`/cards/${this.id}`);
    this.mapData(res.data);
  }

}
