
import { Component, Vue, Watch } from 'vue-property-decorator';
import { BillingPackageModel } from '@/models/billing_package';
import { RuleModel } from '@/models/rule';
import { RuleList } from '@/collections/rules';

@Component({
  components: {
  },
})
export default class BillingPackageNewPage extends Vue {
  public isLoading: boolean = true;
  public billingPackage: BillingPackageModel = new BillingPackageModel();
  public messageError: string = '';
  public isError: boolean = false;
  public rules: RuleList = new RuleList();
  public selectingRules: RuleList = new RuleList();
  public selectedRules: RuleList = new RuleList();

  public async created() {
    this.billingPackage.rules = new RuleList();
    await this.rules.fetch();
    this.selectingRules.items = [...this.rules.items];
  }

  public addRule() {
    this.selectedRules.add(new RuleModel());
    this.billingPackage.rules.add(new RuleModel());
  }

  public async createBillingPackage() {
    if (!this.billingPackage.name || this.billingPackage.name === '') {
      this.isError = true;
      this.messageError = 'Name is a required param';
      return;
    }

    const existedRuleIds: number[] = [];
    for (const rule of this.billingPackage.rules.items) {
      if (!existedRuleIds.includes(rule.id)) {
        existedRuleIds.push(rule.id);
      } else {
        this.isError = true;
        this.messageError = 'There are same rules in this billing package';
        return;
      }
    }

    await this.billingPackage.save();
    await this.$router.push('/billing_packages');
  }

  public async onRulesChanged(index: number) {
    for (const rule of this.rules.items) {
      if (this.selectedRules.get(index).id === rule.id) {
        this.selectedRules.items[index] = rule;
      }
    }
    this.billingPackage.rules.items[index] = this.selectedRules.get(index);
  }
}
