
import { Component, Vue } from 'vue-property-decorator';
import { TagList } from '@/collections/tags';
import { TagTypeList } from '@/collections/tag_types';

@Component({
  components: {
  },
})
export default class Tags extends Vue {
  public isLoading: boolean = true;
  public tags: TagList = new TagList();
  public tagTypes: TagTypeList = new TagTypeList();

  public async created() {
    this.isLoading = true;
    await this.tagTypes.fetch();
    await this.tags.fetch();

    for (const tag of this.tags.items) {
      for (const tagType of this.tagTypes.items) {
        if (tagType.id === tag.tagType.id) {
          tag.tagType = tagType;
          break;
        }
      }
    }
    this.isLoading = false;
  }
}
