import { get, post, put, del } from '@/services/http';

export class FaqModel {
  public id: number;
  public question: string;
  public answer: string;
  public link: string;
  public status: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.question = data.question;
    this.answer = data.answer;
    this.link = data.link;
    this.status = data.status;
  }

  public async fetch() {
    const res: any = await get(`/faqs/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    const res: any = await post('/faqs', {
      question: this.question,
      answer: this.answer,
      link: this.link,
    });
    this.mapData(res.data);
  }

  public async update() {
    const res: any = await put(`/faqs/${this.id}`, {
      question: this.question,
      answer: this.answer,
      link: this.link,
    });
  }

  public async del() {
    const res: any = await del(`/faqs/${this.id}`);
  }

  public async inactive() {
    const res: any = await put(`/faqs/${this.id}/inactive`);
  }

  public async active() {
    const res: any = await put(`/faqs/${this.id}/active`);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.question = data.question;
    this.answer = data.answer;
    this.link = data.link;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
