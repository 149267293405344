import { get, post, put, del } from '@/services/http';

export class DashboardModel {
  public storeTotalCount: number;
  public paidStoreTotalCount: number;
  public paidBasicStoreTotalCount: number;
  public paidProfessionalStoreTotalCount: number;
  public paidAdvancedStoreTotalCount: number;
  public paidPlusStoreTotalCount: number;
  public paidFreeStoreTotalCount: number;
  public freeStoreTotalCount: number;
  public passedTrialStoreTotalCount: number;
  public passedTrialStoreAvgPrice: number;
  public newStoreCount: number;
  public paidStoreCount: number;
  public uninstalledStoreCount: number;
  public incommingStoreCount: number;
  public notCompleteOnboardStoreTotalCount: number;
  public startDate!: string;
  public endDate!: string;

  constructor(data: any = {}) {
    this.storeTotalCount = 0;
    this.paidStoreTotalCount = 0;
    this.paidBasicStoreTotalCount = 0;
    this.paidProfessionalStoreTotalCount = 0;
    this.paidAdvancedStoreTotalCount = 0;
    this.paidPlusStoreTotalCount = 0;
    this.paidFreeStoreTotalCount = 0;

    this.freeStoreTotalCount = 0;
    this.passedTrialStoreTotalCount = 0;
    this.passedTrialStoreAvgPrice = 0;
    this.newStoreCount = 0;
    this.paidStoreCount = 0;
    this.uninstalledStoreCount = 0;
    this.incommingStoreCount = 0;
    this.notCompleteOnboardStoreTotalCount = 0;
  }

  public async getStoreTotalCount() {
    const res: any = await get('/stores/count');
    this.storeTotalCount = res.data;
  }

  public async getPaidStoreTotalCount(packageType: string = '') {
    let apiUrl = '/paid_stores/count';
    if (packageType !== '') {
      apiUrl += `?package_type=${packageType}`;
    }
    const res: any = await get(apiUrl);
    if (packageType === '') {
      this.paidStoreTotalCount = res.data;
    } else if (packageType === 'Basic Shopify') {
      this.paidBasicStoreTotalCount = res.data;
    } else if (packageType === 'Professional') {
      this.paidProfessionalStoreTotalCount = res.data;
    } else if (packageType === 'Advanced Shopify') {
      this.paidAdvancedStoreTotalCount = res.data;
    } else if (packageType === 'Shopify Plus') {
      this.paidPlusStoreTotalCount = res.data;
    } else if (packageType === 'Free') {
      this.paidFreeStoreTotalCount = res.data;
    }
  }

  public async getPassedTrialStoreTotalCount() {
    const res: any = await get('/passed_trial_stores/count');
    this.passedTrialStoreTotalCount = res.data;
  }

  public async getIncommingStoreTotalCount() {
    const res: any = await get('/paid_stores/count?incomming=true');
    this.incommingStoreCount = res.data;
  }

  public async getPassedTrialStoreAvgPrice() {
    const res: any = await get('/passed_trial_stores/avg_price');
    this.passedTrialStoreAvgPrice = res.data;
  }

  public async getNotCompleteOnboardStoreTotalCount() {
    const res: any = await get('/stores/not_complete_onboard/count');
    this.notCompleteOnboardStoreTotalCount = res.data;
  }
}
