import { get, del } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import List from './list';

interface Params {
  isOlap?: boolean;
  isPositiveTrend?: boolean;
  hasMetric?: boolean;
  hasTimerange?: boolean;
  hasDimension?: boolean;
}

export class MeasureList extends List<MeasureModel> {
  public async fetch(params: Params = {}) {
    const keys: string[] = Object.keys(params);
    const options: Params = {};
    if (keys.includes('isOlap')) {
      options.isOlap = params.isOlap;
    }
    if (keys.includes('hasMetric')) {
      options.hasMetric = params.hasMetric;
    }
    if (keys.includes('hasTimerange')) {
      options.hasTimerange = params.hasTimerange;
    }
    if (keys.includes('isPositiveTrend')) {
      options.isPositiveTrend = params.isPositiveTrend;
    }
    if (keys.includes('hasDimension')) {
      options.hasDimension = params.hasDimension;
    }

    const res: any = await get(`/measures`, options);
    const measures = res.data || [];
    for (const s of measures) {
      const measure = new MeasureModel();
      measure.mapData(s);
      this.add(measure);
    }
  }

  public async clearCache() {
    const res: any = await del(`/measures`);
  }
}
