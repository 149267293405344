
import { Component, Vue } from 'vue-property-decorator';
import { DefaultMetricList } from '@/collections/default_metrics';
import { MeasureList } from '@/collections/measures';
import { DefaultMetricModel } from '@/models/default_metric';

@Component({
  components: {
  },
})
export default class DefaultMetrics extends Vue {
  public isLoading: boolean = true;
  public defaultMetrics: DefaultMetricList = new DefaultMetricList();
  public measures: MeasureList = new MeasureList();

  public async created() {
    this.isLoading = true;
    await this.defaultMetrics.fetch();
    await this.measures.fetch();

    for (const defaultMetric of this.defaultMetrics.items) {
      for (const measure of this.measures.items) {
        if (defaultMetric.measure.code === measure.code && measure.code) {
          defaultMetric.measure = measure;
          break;
        }
      }
    }
    this.isLoading = false;
  }

  public async deleteDefaultMetric(defaultMetric: DefaultMetricModel) {
    await defaultMetric.inactive();
    this.defaultMetrics = new DefaultMetricList();
    await this.defaultMetrics.fetch();
  }
}
