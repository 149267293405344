import { post } from '@/services/http';

export class FunnelEventModel {
  public id: number;
  public name: string;
  public happenedAt?: string;
  public attributes: any;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.attributes = data.attributes;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.happenedAt = data.happenedAt;
    this.attributes = data.attributes;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  public create(merchantId: number) {
    return post('/system_events', {
      store_id: merchantId,
      event: this.name,
      attributes: this.attributes,
    });
  }
}
