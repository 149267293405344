
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TagTypeModel } from '@/models/tag_type';
import { TagList } from '@/collections/tags';

@Component({
  components: {
  },
})
export default class TemplateReportEditPage extends Vue {
  public isLoading: boolean = true;
  public tagType: TagTypeModel = new TagTypeModel();
  public tags: TagList = new TagList();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    this.isLoading = true;
    this.tagType.id = Number(this.$route.params.id);
    await this.tagType.fetch();
    this.tags.fetch();
    this.isLoading = false;
  }

  public async changeStatus() {
    this.isLoading = true;
    await this.tagType.changeStatus();
    await this.$router.push('/tag_types');
    this.isLoading = false;
  }

  public async deleteTagType() {
    this.isLoading = true;
    await this.tagType.del();
    await this.$router.push('/tag_types');
    this.isLoading = false;
  }

  public async updateTagType() {
    if (!this.tagType.name || this.tagType.name === '') {
      this.isError = true;
      this.messageError = 'Name is a required param';
      return;
    }

    await this.tagType.update();
    await this.$router.push('/tag_types');
  }
}
