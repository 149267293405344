import { post } from '@/services/http';

export class NoteModel {
  public id: number;
  public note: string;
  public merchantId: number;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.note = data.note;
    this.merchantId = data.merchantId;
  }

  public async save() {
    const res: any = await post(`/stores/${this.merchantId}/notes`, {
      note: this.note,
      merchantId: this.merchantId,
    });
    this.mapData(res.data);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.note = data.note;
    this.merchantId = data.merchantId;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
