
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FaqModel } from '@/models/faq';

@Component({
  components: {
  },
})
export default class FaqNewPage extends Vue {
  public isLoading: boolean = false;
  public faq: FaqModel = new FaqModel();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    // skipped
  }

  public async createFaq() {
    if (!this.faq.question || this.faq.question === '') {
      this.isError = true;
      this.messageError = 'Question is a required param';
      return;
    }
    if (!this.faq.answer || this.faq.answer === '') {
      this.isError = true;
      this.messageError = 'Question is a required param';
      return;
    }

    this.isLoading = true;
    await this.faq.save();
    this.isLoading = false;
    await this.$router.push('/faqs');
  }
}
