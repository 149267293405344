import { get, post, put, del } from '@/services/http';
import { TagTypeModel } from '@/models/tag_type';

export class TagModel {
  public id: number;
  public name: string;
  public sort: number;
  public tagType: TagTypeModel;
  public description: string;
  public status: string;
  public createdAt?: string;
  public updatedAt?: string;
  public terms?: string[];
  public link?: string;
  public isUsecase?: boolean;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.sort = data.sort;
    this.tagType = new TagTypeModel();
    this.status = data.status;
    this.description = data.description;
    this.isUsecase = false;
  }

  public async fetch() {
    const res: any = await get(`/tags/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    const res: any = await post('/tags', {
      name: this.name,
      number: this.sort,
      tagTypeId: this.tagType.id,
      description: this.description,
      terms: this.terms ? this.terms.join(',') : null,
      link: this.link,
      isUsecase: this.isUsecase,
    });
    this.mapData(res.data);
  }

  public async update() {
    const res: any = await put(`/tags/${this.id}`, {
      name: this.name,
      number: this.sort,
      tagTypeId: this.tagType.id,
      description: this.description,
      terms: this.terms ? this.terms.join(',') : null,
      link: this.link,
      isUsecase: this.isUsecase,
    });
  }

  public async del() {
    const res: any = await del(`/tags/${this.id}`);
  }

  public async inactive() {
    const res: any = await put(`/tags/${this.id}/inactive`);
  }

  public async active() {
    const res: any = await put(`/tags/${this.id}/active`);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.sort = data.number;
    this.tagType = new TagTypeModel({ id: data.tagTypeId });
    this.status = data.status;
    this.description = data.description;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.terms = data.terms ? data.terms.split(',') : null;
    this.link = data.link;
    this.isUsecase = data.isUsecase;
  }
}
