
import { Component, Vue } from 'vue-property-decorator';
import { UserPackageList } from '@/collections/user_packages';
import { NoteList } from '@/collections/notes';
import { StoreModel } from '@/models/store';
import { UserPackageModel } from '@/models/user_package';
import { NoteModel } from '@/models/note';
import { BillingPackageModel } from '@/models/billing_package';
import { FunnelEventModel } from '@/models/funnelEvent';
import StoreNotes from '@/components/StoreNotes.vue';

@Component({
  components: {
    StoreNotes,
  },
})
export default class Store extends Vue {
  public isLoading: boolean = true;
  public store: StoreModel = new StoreModel();
  public userPackages: UserPackageList = new UserPackageList();
  public userPackage: UserPackageModel = new UserPackageModel();
  public billingPackage: BillingPackageModel = new BillingPackageModel();
  public savingUserPackage: boolean = false;
  public notes: NoteList = new NoteList();
  public dialog: boolean = false;
  public dialogExtendTrial: boolean = false;
  public newNote: NoteModel = new NoteModel();
  public savingNote: boolean = false;
  public loadingLogin: boolean = false;
  public dialogAction: boolean = false;
  public reportBugAction: boolean = false;
  public cleanCacheAction: boolean = false;
  public reloadWebhookAction: boolean = false;
  public dialogActionProgress: boolean = false;
  public dialogText: string = '';
  public dialogActionMessage: string = '';
  public trialDaysLeft: number = 0;
  public extendDays: number = 7;
  public loadingExtendTrial: boolean = false;
  public extendTrialErrorMessage: string = '';
  public extendTrialSuccessMessage: string = '';
  public extendTrialUrl: string = '';
  public testInventoryAction: boolean = false;
  public testInventoryMessage: string = 'Click the button to test inventory now';
  public dialogTestInventory: boolean = false;

  public async created() {
    this.isLoading = true;
    this.store.id = Number(this.$route.params.id);
    this.userPackages.merchantId = this.store.id;
    this.newNote.merchantId = this.store.id;
    await this.store.fetch();
    await this.userPackages.fetch();
    for (const item of this.userPackages.items) {
      if (item.status === 'ACTIVE') {
        this.userPackage = item;
        if (item.price > 0) {
          const trialEndAt = new Date((item.trialEndAt) ? item.trialEndAt : '');
          const currentDate = new Date();
          if (trialEndAt < currentDate) {
            this.trialDaysLeft = 0;
          } else {
            const timeDiff = Math.abs(trialEndAt.getTime() - currentDate.getTime());
            this.trialDaysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
          }
        }
        break;
      }
    }
    this.billingPackage.id = this.userPackage.billingPackageId;
    if (this.billingPackage.id) {
      await this.billingPackage.fetch();
    }
    const newRules = [];
    const currentRuleIds = [];
    for (const rule of this.userPackage.rules.items) {
      currentRuleIds.push(rule.id);
    }
    for (const billingRule of this.billingPackage.rules.items) {
      if (!currentRuleIds.includes(billingRule.id)) {
        newRules.push(billingRule);
      }
    }
    for (const rule of newRules) {
      rule.status = 'INACTIVE';
      this.userPackage.rules.add(rule);
    }
    await this.notes.fetch(this.store.id);
    this.isLoading = false;
  }

  public async testInventory() {
    this.testInventoryMessage = '';
    this.testInventoryAction = true;
    try {
      const res: any = await this.store.testInvetory();
      if (res && res.test_pass === 'true' || res.test_pass === true) {
        this.testInventoryMessage = 'The inventory is up-to-date';
      } else {
        this.testInventoryMessage = 'The inventory is not up-to-date';
      }
    } catch (e) {
      this.testInventoryMessage = 'Test inventory failed';
    }
    this.testInventoryAction = false;
  }

  public async saveUserPackage() {
    this.savingUserPackage = true;
    await this.userPackage.save();
    this.savingUserPackage = false;
  }

  public async extendTrial() {
    this.loadingExtendTrial = true;
    const res: any = await this.store.extendTrial(this.extendDays);
    if (res.isError) {
      this.extendTrialErrorMessage = res.message;
      this.extendTrialSuccessMessage = '';
      this.extendTrialUrl = '';
    } else {
      this.extendTrialErrorMessage = '';
      this.extendTrialSuccessMessage = res.message;
      this.extendTrialUrl = res.data;
    }
    this.loadingExtendTrial = false;
  }

  public async createNewNote() {
    this.savingNote = true;
    await this.newNote.save();
    this.newNote = new NoteModel({merchantId: this.store.id});
    this.notes = new NoteList();
    await this.notes.fetch(this.store.id);
    this.savingNote = false;
    this.dialog = false;
  }

  public async loginAsMerchant() {
    this.loadingLogin = true;
    const token: string = await this.store.generateLink();
    this.loadingLogin = false;
    window.open(`https://my.assisty.ai?hades=${token}`, '_blank');
  }

  public async bugReport() {
    this.reportBugAction = true;
    this.dialogAction = true;
    this.dialogActionProgress = true;
    this.dialogActionMessage = '';
    this.dialogText = 'Report bug merchant';
    const res = await this.store.bugReport();
    this.dialogActionMessage = res.message;
    this.dialogActionProgress = false;
    this.reportBugAction = false;
  }

  public async clearCache() {
    this.cleanCacheAction = true;
    this.dialogAction = true;
    this.dialogActionProgress = true;
    this.dialogActionMessage = '';
    this.dialogText = 'Clean query caches';
    const res = await this.store.clearCache();
    this.dialogActionMessage = res.message;
    this.dialogActionProgress = false;
    this.cleanCacheAction = false;
  }

  public async reloadWebhook() {
    this.reloadWebhookAction = true;
    this.dialogAction = true;
    this.dialogActionProgress = true;
    this.dialogActionMessage = '';
    this.dialogText = 'Reload webhook data';
    const res = await this.store.reloadWebhook();
    this.dialogActionMessage = res.message;
    this.dialogActionProgress = false;
    this.reloadWebhookAction = false;
  }

  public detectLink(text: string) {
    return text.replace(
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
      '<a href="$1" target="_blank">$1</a>',
    );
  }

  public async confirmPassedDataVerification() {
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'DATA_VERIFICATION_HUMAN_PASSED',
      });
      await funnelEvent.create(this.store.id);
    } catch (error) {
      // skipped
    }
  }
}
