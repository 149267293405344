
import { Component, Vue, Watch } from 'vue-property-decorator';
import { PluginModel } from '@/models/plugin';
import { BillingPackageList } from '@/collections/billing_packages';
import { RuleList } from '@/collections/rules';
import { PluginPackageModel } from '@/models/plugin_package';
import { PluginRuleModel } from '@/models/plugin_rule';
import { RuleModel } from '@/models/rule';

@Component({
  components: {
  },
})
export default class PluginEdit extends Vue {
  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public plugin: PluginModel = new PluginModel();
  public messageError: string = '';
  public isError: boolean = false;
  public billingPackages: BillingPackageList = new BillingPackageList();
  public rules: RuleList = new RuleList();

  public async created() {
    this.isLoading = true;
    this.plugin = new PluginModel();
    const id = Number(this.$route.params.id);
    if (id) {
      this.plugin.id = id;
      await this.plugin.fetch();
    }
    await this.billingPackages.fetch({status: 'ACTIVE'});
    await this.rules.fetch();
    this.isLoading = false;
  }

  public async onRulesChanged(index: number) {
    const rule = this.getRule(this.plugin.rules.items[index].ruleId);
    this.plugin.rules.items[index].ruleCode = rule.code;
    this.plugin.rules.items[index].value = '';
  }

  public getRule(ruleId: number) {
    for (const rule of this.rules.items) {
      if (rule.id === ruleId) {
        return rule;
      }
    }
    return new RuleModel();
  }
  public deleteFeature(index: number) {
    this.plugin.features.splice(index, 1);
  }

  public deleteImage(index: number) {
    this.plugin.images.splice(index, 1);
  }

  public deletePackage(index: number) {
    const pluginPackage = this.plugin.packages.items[index];
    pluginPackage.delete();
    this.plugin.packages.items.splice(index, 1);
  }

  public addPackage() {
    const newPackage = new PluginPackageModel();
    newPackage.pluginId = this.plugin.id;
    this.plugin.packages.add(newPackage);
  }

  public deleteRule(index: number) {
    const pluginRule = this.plugin.rules.items[index];
    pluginRule.delete();
    this.plugin.rules.items.splice(index, 1);
  }

  public addRule() {
    const newRule = new PluginRuleModel();
    newRule.pluginId = this.plugin.id;
    this.plugin.rules.add(newRule);
  }

  public addFeature() {
    this.plugin.features.push('');
  }

  public addImage() {
    this.plugin.images.push('');
  }

  public async savePlugin() {
    this.isSaving = true;
    try {
      if (this.plugin.id) {
        await this.plugin.save();
      } else {
        await this.plugin.save();
        window.location.assign(`/plugin/edit/${this.plugin.id}`);
      }

    } catch (error) {
      // dosomething with the error
    }
    this.isSaving = false;
  }

  public async changeStatusPlugin() {
    this.isSaving = true;
    try {
      await this.plugin.changeStatus();
    } catch (error) {
      // console.log('There is an error when changing the status');
      this.isSaving = false;
    }
    this.isSaving = false;
  }
}
