
import { Component, Vue, Prop } from 'vue-property-decorator';
import { post } from '@/services/http';

@Component({
  components: {
  },
})
export default class ImageUploader extends Vue {
  public isLoading: boolean = false;
  public rules: any = [
    (value: any) => {
      return !value || value.size < 2000000 || 'Image size should be less than 2MB!';
    },
  ];
  public filePath: string = '';
  public file: File|null = null;
  @Prop({ default: null })
  public value!: string;

  public created() {
    this.filePath = this.value;
  }

  public async uploadImage(file: File) {
    if (!file) {
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', file);
    const res: any = await post(
      'https://files.assisty.ai/upload',
      formData,
      true,
    );
    this.filePath = res.file;
    this.file = null;
    this.$emit('input', this.filePath);
    this.isLoading = false;
  }
}
