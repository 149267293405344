import { get, post } from '@/services/http';
import { ReportCardModel } from '@/models/report_card';
import List from './list';

export class ReportCardList extends List<ReportCardModel> {

  /*public async fetch() {
    const res: any = await get(`/tag_types`, {});
    const tagTypes = res.data || [];
    for (const s of tagTypes) {
      const tagType = new TagTypeModel();
      tagType.mapData(s);
      this.add(tagType);
    }
  }*/
}
