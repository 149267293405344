
import { Component, Vue } from 'vue-property-decorator';
import LogoBar from '@/components/LogoBar.vue';
import SessionModel from '@/models/session';

@Component({
  components: {
    LogoBar,
  },
})
export default class SignInPage extends Vue {
  public email: string = '';
  public password: string = '';
  public returnUrl: string = '/';
  public session: SessionModel = new SessionModel();

  public async handleLogin() {
    const { email, password } = this;
    if (!(email && password)) {
      return;
    }
    await this.session.create(email, password);
    this.$router.push(this.returnUrl);
  }
}
