import { get, post } from '@/services/http';
import { CustomReportModel } from '@/models/custom_report';
import List from './list';

interface SearchParams {
  name?: string;
}

export class CustomReportList extends List<CustomReportModel> {
  public lastPage!: number;
  public mapData(data: any = []) {
    for (const s of data) {
      const report = new CustomReportModel();
      report.mapData(s);
      this.add(report);
    }
  }

  public async fetch(
    page: number = 1,
    pageSize: number = 50,
    params: SearchParams = {},
    ) {
      const data: any = {
        page,
        per_page: pageSize,
        ...params,
      };
      const res: any = await get(`/custom_reports`, data);
      const reports = res.data.rows || [];
      this.lastPage = Number((res.data.count / pageSize).toFixed(0));
      if (res.data.count / pageSize > this.lastPage) {
        this.lastPage = this.lastPage + 1;
      }
      for (const s of reports) {
        const report: CustomReportModel = new CustomReportModel();
        report.mapData(s);
        this.add(report);
      }
  }
}
