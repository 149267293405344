
import { Component, Vue } from 'vue-property-decorator';
import { ResourceList } from '@/collections/resources';

@Component({
  components: {
  },
})
export default class ResourcePage extends Vue {
  public isLoading: boolean = true;
  public resources: ResourceList = new ResourceList();

  public async created() {
    this.isLoading = true;
    await this.resources.fetch();
    this.isLoading = false;
  }
}
