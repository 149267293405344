
import { Component, Vue, Watch } from 'vue-property-decorator';
import {ResourceModel} from '@/models/resource';
import {RuleList} from '@/collections/rules';
import {RuleModel} from '@/models/rule';
import {PluginModel} from '@/models/plugin';

@Component({
  components: {
  },
})
export default class ResourceNewPage extends Vue {
  public isLoading: boolean = false;
  public resource: ResourceModel = new ResourceModel();
  public rules: RuleList = new RuleList();
  public messageError: string = '';
  public isError: boolean = false;
  public selectedRule: number = 0;

  public async created() {
    this.isLoading = true;
    this.resource = new ResourceModel();
    await this.rules.fetch();
    const id = Number(this.$route.params.id);
    if (id) {
      this.resource.id = id;
      await this.resource.fetch();
    }
    this.selectedRule = this.getRuleId(this.resource.ruleCode);
    this.isLoading = false;
  }

  public async createResource() {
    if (!this.resource.name || this.resource.name === '') {
      this.isError = true;
      this.messageError = 'Name is a required param';
      return;
    }
    if (!this.resource.code || this.resource.code === '') {
      this.isError = true;
      this.messageError = 'Code is a required param';
      return;
    }
    if (this.resource.code.includes(' ')) {
      this.isError = true;
      this.messageError = 'Code must not include space';
      return;
    }
    if (!this.resource.type || this.resource.type === '') {
      this.isError = true;
      this.messageError = 'Type is a required param';
      return;
    }

    this.resource.ruleCode = this.getRule(this.selectedRule).code;

    const result = await this.resource.save();
    if (result) {
      await this.$router.push('/resource');
    }
  }

  public getRule(ruleId: number) {
    for (const rule of this.rules.items) {
      if (rule.id === ruleId) {
        return rule;
      }
    }
    return new RuleModel();
  }

  public getRuleId(ruleCode: string) {
    for (const rule of this.rules.items) {
      if (rule.code === ruleCode) {
        return rule.id;
      }
    }
    return 0;
  }
}
