import { get, post } from '@/services/http';
import { PermissionModel } from '@/models/permission';
import List from './list';

export class PermissionList extends List<PermissionModel> {
  public lastPage!: number;

  public async fetch(
    page: number = 1,
    pageSize: number = 50,
  ) {
    const data: any = {
      page,
      per_page: pageSize,
    };
    const res: any = await get(`/permissions`, data);
    const permissions = res.data.rows || [];
    this.lastPage = Number((res.data.count / pageSize).toFixed(0));
    if (res.data.count / pageSize > this.lastPage) {
      this.lastPage = this.lastPage + 1;
    }
    for (const s of permissions) {
      const permission: PermissionModel = new PermissionModel();
      permission.mapData(s);
      this.add(permission);
    }
  }
}
