
import { Component, Vue } from 'vue-property-decorator';
import { DiscountList } from '@/collections/discounts';

@Component({
  components: {
  },
})
export default class Discounts extends Vue {
  public isLoading: boolean = true;
  public discounts: DiscountList = new DiscountList();
  public currentPage: number = 1;
  public perPage: number = 50;
  public lastPage: number = 1;
  public copyLoading: boolean = false;
  public copyMessage: string = '';

  public async created() {
    this.isLoading = true;
    await this.discounts.fetch(
      this.currentPage,
      this.perPage,
    );
    this.lastPage = this.discounts.lastPage;
    this.isLoading = false;
  }

  public async wait(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  public async copyToClipboard(discountCode: string) {

    const url: string = 'https://my.assisty.ai/auto_subscription?discount=' + discountCode;
    try {
        await navigator.clipboard.writeText(url);
    } catch (err) {
      //
    }
    this.copyLoading = true;
    this.copyMessage = url;
    await this.wait(4000);
    this.copyLoading = false;
  }

  public async fetchData() {
    this.discounts = new DiscountList();
    await this.discounts.fetch(
      this.currentPage,
      this.perPage,
    );

    this.lastPage = this.discounts.lastPage;
  }
}
