import { get, post } from '@/services/http';
import { PluginModel } from '@/models/plugin';
import List from './list';

export class PluginList extends List<PluginModel> {
  public mapData(data: any = []) {
    for (const s of data) {
      const plugin = new PluginModel();
      plugin.mapData(s);
      this.add(plugin);
    }
  }

  public async fetch() {
    const data: any = {};
    const res: any = await get(`/plugins`, data);
    const plugins = res.data || [];
    for (const pluginData of plugins) {
      const plugin: PluginModel = new PluginModel();
      plugin.mapData(pluginData);
      this.add(plugin);
    }
  }
}
