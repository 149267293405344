
import { Component, Vue } from 'vue-property-decorator';
import { StoreList } from '@/collections/stores';
import { StoreModel } from '@/models/store';

@Component({
  components: {
  },
})
export default class ActiveTrialStores extends Vue {
  public isLoading: boolean = true;
  public stores: StoreList = new StoreList();

  public async created() {
    await this.fetchData();
  }

  public async fetchData() {
    this.isLoading = true;
    this.stores = new StoreList();
    await this.stores.getActiveTrialStores();
    this.isLoading = false;
  }

  public async loginAsMerchant(store: StoreModel) {
    const token: string = await store.generateLink();
    window.open(`https://my.assisty.ai?hades=${token}`, '_blank');
  }

  public convertDate(date: string = '') {
    return new Date(date).toISOString().slice(0, 10).replace('T', ' ');
  }

}
