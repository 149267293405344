
import {Component, Watch, Vue, Prop} from 'vue-property-decorator';
import Chart from 'chart.js/auto';


@Component({})
export default class ReportCardPieChart extends Vue {
  public chart?: any;
  @Prop({default: false})
  public isLoadedReport!: boolean;
  @Prop({default: {}})
  public report?: any;

  public constructor() {
    super();
  }

  public reload() {
    const ctx = document.getElementById('pieChartReport');
    if (ctx instanceof HTMLCanvasElement) {
      if (this.chart) {
        this.chart.destroy();
      }
      /* Chart.defaults.font.size = 16; */
      /* Chart.defaults.font.family = 'Oxygen'; */
      Chart.defaults.elements.point.radius = 0;
      Chart.defaults.elements.point.hoverRadius = 5;

      const data = {
        labels: this.report.columns,
        datasets: [{
          data: this.report.data,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(22, 205, 86)',
            'rgb(22, 22, 86)',
          ],
        }],
      };

      this.chart = new Chart(ctx, {
        type: 'pie',
        data: data,
        options: {
          aspectRatio: 1.6, // Control the aspect ratio (1 is a square)
          responsive: true, // Enable responsiveness
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'right',
              align: 'start',
              labels: {
                font: {
                  size: 16
                },
                textAlign: 'left',
                generateLabels: (chart: any) => {
                  const datasets = chart.data.datasets;
                  return datasets[0].data.map((data: any, i: number) => ({
                    text: `${chart.data.labels[i]}: ${data}`,
                    fillStyle: datasets[0].backgroundColor[i],
                    index: i
                  }));
                },
              },
            },
            tooltip: {
              callbacks: {
                label: function(context: any) {
                  // Calculate the percentage value
                  const dataset = context.dataset;
                  const value = dataset.data[context.dataIndex];
                  const total = dataset.data.reduce((sum: number, currentValue: number) => sum + currentValue, 0);
                  const percentage = ((value / total) * 100).toFixed(2) + '%';
                  return `${context.label}: ${value} (${percentage})`;
                },
              },
            },
          },
        },
      });
    }
  }

  @Watch('isLoadedReport', { immediate: true, deep: true  })
  private async onIsLoadedReportChanged(newVal: any, oldVal: any) {
    if (newVal === true) {
      this.reload();
    }
  }
}
