import { get } from '@/services/http';

export class DimensionColumnModel {
  public id: number;
  public name: string;
  public code: string;
  public luis_mapping?: string;
  public label?: string;
  public data_type?: string;
  public group_name?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.luis_mapping = data.luis_mapping;
    this.label = data.label;
    this.data_type = data.data_type;
    this.group_name = data.group_name;
  }
}
