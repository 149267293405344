
import { Component, Vue } from 'vue-property-decorator';
import { CustomReportModel } from '@/models/custom_report';
import { CustomReportList } from '@/collections/custom_reports';


@Component({
  components: {
  },
})
export default class CustomReports extends Vue {
  public isLoading: boolean = true;
  public reports: CustomReportList = new CustomReportList();
  public currentPage: number = 1;
  public perPage: number = 50;
  public lastPage: number = 1;
  public keyword: string = '';
  public dialog: boolean = false;

  public async created() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.perPage = Number(this.$route.query.per_page) || 50;
    await this.fetchData();
  }

  public async fetchData() {
    this.isLoading = true;
    this.reports = new CustomReportList();
    let params = {};
    if (this.keyword) {
      params = {
        name: this.keyword,
      };
    }

    await this.reports.fetch(
      this.currentPage,
      this.perPage,
      params,
    );
    this.lastPage = this.reports.lastPage;
    this.isLoading = false;
  }

  public clearFiler() {
    this.keyword = '';
    this.currentPage = 1;
    this.fetchData();
  }
}
