import { get, post } from '@/services/http';
import { ResourceModel } from '@/models/resource';
import List from './list';

export class ResourceList extends List<ResourceModel> {
    public async fetch() {
        const res: any = await get(`/resource`, {});
        const resources = res.data || [];
        this.mapData(resources);
    }

    public mapData(resources: ResourceModel[]) {
        for (const r of resources) {
            const resource = new ResourceModel();
            resource.mapData(r);
            this.add(resource);
        }
    }
}
