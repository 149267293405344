
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TagTypeList } from '@/collections/tag_types';
import { TagModel } from '@/models/tag';

@Component({
  components: {
  },
})
export default class TagNewPage extends Vue {
  public isLoading: boolean = true;
  public tag: TagModel = new TagModel();
  public tagTypes: TagTypeList = new TagTypeList();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    await this.tagTypes.fetch();
  }

  public async createTag() {
    if (!this.tag.name || this.tag.name === '') {
      this.isError = true;
      this.messageError = 'Name is a required param';
      return;
    }

    await this.tag.save();
    await this.$router.push('/tags');
  }
}
