
import { Component, Vue } from 'vue-property-decorator';
import { PermissionModel } from '@/models/permission';

@Component({
  components: {
  },
})
export default class PermissionEditPage extends Vue {
  public isLoading: boolean = true;
  public permission: PermissionModel = new PermissionModel();

  public async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  }

  public async fetchData() {
    this.permission.id = Number(this.$route.params.id);
    await this.permission.fetch();
  }

  public async update() {
    await this.permission.update();
    await this.$router.push('/permissions');
  }
}
