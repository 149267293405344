import { get, post, put } from '@/services/http';
import { MeasureModel } from '@/models/measure';

export class DefaultMetricModel {
  public id: number;
  public measure: MeasureModel;
  public status: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.measure = new MeasureModel();
    this.status = data.status;
  }

  public async save() {
    const res: any = await post('/default_metrics', {
      measureCode: this.measure.code,
    });
    this.mapData(res.data);
  }

  public async inactive() {
    const res: any = await put(`/default_metrics/${this.id}`);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.measure = new MeasureModel({ code: data.measureCode });
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
