import { get, post } from '@/services/http';
import { StoreMetadataModel } from '@/models/store_metadata';
import List from './list';

interface StoreParams {
    keyword?: string;
    status?: string;
    subType?: string;
    tag?: string;
}

export class StoreMetadataList extends List<StoreMetadataModel> {
    public lastPage!: number;
    public total!: number;

    public async fetch(
        page: number = 1,
        perPage: number = 30,
        params: StoreParams = {},
    ) {
        const data: any = {
            page,
            perPage,
            ...params,
        };
        const res: any = await get(`/store_metadata`, data);
        const result = res.data || [];
        this.total = result.count || 0;
        this.lastPage = Number((result.count / perPage).toFixed(0));
        if (result.count / perPage > this.lastPage) {
            this.lastPage = this.lastPage + 1;
        }
        this.mapData(result.rows);
    }

    public mapData(resources: StoreMetadataModel[]) {
        for (const r of resources) {
            const resource = new StoreMetadataModel();
            resource.mapData(r);
            this.add(resource);
        }
    }
}
