
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RuleModel } from '@/models/rule';

@Component({
  components: {
  },
})
export default class RuleNewPage extends Vue {
  public isLoading: boolean = true;
  public rule: RuleModel = new RuleModel();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    this.isLoading = true;
    this.rule.id = Number(this.$route.params.id);
    await this.rule.fetch();
    this.isLoading = false;
  }

  public async updateRule() {
    if (!this.rule.name || this.rule.name === '') {
      this.isError = true;
      this.messageError = 'Name is a required param';
      return;
    }
    if (!this.rule.code || this.rule.code === '') {
      this.isError = true;
      this.messageError = 'Code is a required param';
      return;
    }
    if (this.rule.code.includes(' ')) {
      this.isError = true;
      this.messageError = 'Code must not include space';
      return;
    }
    if (!this.rule.type || this.rule.type === '') {
      this.isError = true;
      this.messageError = 'Type is a required param';
      return;
    }
    if (!this.rule.value || this.rule.value === '') {
      this.isError = true;
      this.messageError = 'Default Value is a required param';
      return;
    }
    if (this.rule.type === 'string' && this.rule.initValue.length === 0) {
      this.isError = true;
      this.messageError = 'Init Values is a required param for type string';
      return;
    }

    if (this.rule.type === 'lock') {
      this.rule.initValue = ['locked', 'unlocked'];
    }

    await this.rule.update();
    await this.$router.push('/rules');
  }

  public remove(item: string) {
    this.rule.initValue.splice(this.rule.initValue.indexOf(item), 1);
    this.rule.initValue = [...this.rule.initValue];
  }
}
