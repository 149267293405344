
import { Component, Vue } from 'vue-property-decorator';
import { BillingPackageList } from '@/collections/billing_packages';

@Component({
  components: {
  },
})
export default class BillingPackages extends Vue {
  public isLoading: boolean = true;
  public billingPackages: BillingPackageList = new BillingPackageList();

  public async created() {
    this.isLoading = true;
    await this.billingPackages.fetch();
    this.isLoading = false;
  }
}
