
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TemplateReportModel } from '@/models/template_report';
import { MeasureList } from '@/collections/measures';
import { DimensionList } from '@/collections/dimensions';
import { AnalyticsTypeList } from '@/collections/analytics_types';
import { TagList } from '@/collections/tags';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import ImageUploader from '@/components/ImageUploader.vue';
import { FilterColumnModel } from '@/models/filter_column';
import { FilterColumnList } from '@/collections/filter_columns';
import FilterColumn from '@/components/FilterColumn.vue';
import ColumnSelector from '@/components/ColumnSelector.vue';


@Component({
  components: {
    ImageUploader,
    FilterColumn,
    ColumnSelector,
  },
})
export default class TemplateReportEditPage extends Vue {
  public isLoading: boolean = true;
  public templateReport: TemplateReportModel = new TemplateReportModel();
  public measures: MeasureList = new MeasureList();
  public dimensions: DimensionList = new DimensionList();
  public analyticsTypes: AnalyticsTypeList = new AnalyticsTypeList();
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public tags: TagList = new TagList();
  public messageError: string = '';
  public isError: boolean = false;
  public deleteDialog: boolean = false;
  public predefinedTimeRanges: string[] = ['today', 'yesterday', '7d', '30d',
    '3m', '6m', '1y', '3y'];
  public terms: string[] = [];
  public selectedReportColumns: any = [];
  public reportColumns: any = [];
  public savedReportColumns: any = [];
  public saveLoading: boolean = false;
  public async created() {
    this.isLoading = true;
    this.templateReport.id = Number(this.$route.params.id);
    await this.getDefault();
    if (this.templateReport.id) {
      await this.templateReport.fetch();
      this.filterColumns = this.templateReport.filterColumns;
      this.initFilterColumns = this.templateReport.filterColumns;
      this.loadInit();
    }
    this.isLoading = false;
  }

  public updatePivotOption(pivotOption: string) {
    this.templateReport.pivotOption = pivotOption;
  }

  public addTerms() {
    this.terms = [];
    const keywords = this.templateReport.terms || [];
    this.templateReport.terms = [];
    const keywordString = keywords.join(',');
    const keywordArray = keywordString.split(',');
    for (const item of keywordArray) {
      const keyword = item.trim();
      if (keyword) {
        this.terms.push(keyword);
        this.templateReport.terms.push(keyword);
      }
    }
  }

  public async getDefault() {
    await this.measures.fetch({ isOlap: true });
    await this.tags.fetch();
  }

  public async deleteTemplateReport() {
    await this.templateReport.del();
    this.deleteDialog = false;
    await this.$router.push('/template_reports');
  }

  public async save() {
    this.saveLoading = true;
    if (!this.templateReport.name || this.templateReport.name === '') {
      this.isError = true;
      this.messageError = 'You must enter name of the report';
      return;
    }
    if (!this.templateReport.measure.code || this.templateReport.measure.code === '') {
      this.isError = true;
      this.messageError = 'You must select a measure';
      return;
    }
    if (!this.templateReport.dimension.code || this.templateReport.dimension.code === '') {
      this.isError = true;
      this.messageError = 'You must select a dimension';
      return;
    }
    this.templateReport.filterColumns = this.filterColumns;
    this.templateReport.reportColumns = this.selectedReportColumns;
    if (this.templateReport.id) {
      const id = this.templateReport.id;
      await this.templateReport.update();
    } else {
      await this.templateReport.save();
      window.location.assign('/template_reports/' + this.templateReport.id);
    }
    this.saveLoading = false;
  }

  public loadInit() {
    for (const measure of this.measures.items) {
      if (measure.code === this.templateReport.measure.code) {
        this.dimensions = measure.dimensions;
        for (const dimension of measure.dimensions.items) {
          if (dimension.code === this.templateReport.dimension.code) {
            this.templateReport.dimension = dimension;
          }
        }

        this.analyticsTypes = measure.analyticsTypes;
        for (const analyticsType of measure.analyticsTypes.items) {
          if (analyticsType.code === this.templateReport.analyticsType.code) {
            this.templateReport.analyticsType = analyticsType;
          }
        }
        break;
      }
    }
    const selectedTags = [];
    for (const selectedTag of this.templateReport.tags.items) {
      for (const tag of this.tags.items) {
        if (Number(selectedTag.id) === tag.id) {
          selectedTags.push(tag);
        }
      }
    }
    this.templateReport.tags.items = selectedTags;
  }

  public onMeasureChanged() {
    for (const measure of this.measures.items) {
      if (measure.code === this.templateReport.measure.code) {
        this.dimensions = measure.dimensions;
        this.templateReport.dimension = new DimensionModel();

        this.analyticsTypes = measure.analyticsTypes;
        this.templateReport.analyticsType = new AnalyticsTypeModel();
        break;
      }
    }
  }
}
