import { get, post, put, del } from '@/services/http';

export class DiscountModel {
  public id: number;
  public createdAt?: string;
  public updatedAt?: string;
  public code: string;
  public rate: number;
  public limitIntervals: number;
  public activatedAt?: string;
  public status: string;
  public cancelledAt?: string;
  public merchantId: number;
  public kind: string;
  public limitUsageNumber: number;
  public appliedTime?: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.code = data.code;
    this.rate = 10;
    this.limitIntervals = 1;
    this.status = 'ACTIVE';
    this.merchantId = data.merchantId;
    this.kind = 'REUSE';
    this.limitUsageNumber = -1;
  }

  public async fetch() {
    const res: any = await get(`/discounts/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    const res: any = await post('/discounts', {
      rate: this.rate,
      limitIntervals: this.limitIntervals,
      status: 'ACTIVE',
      merchantId: this.merchantId,
      kind: this.kind,
      limitUsageNumber: this.limitUsageNumber,
    });
    this.mapData(res.data);
  }

  public async update() {
    const res: any = await put(`/discounts/${this.id}`, {
      rate: this.rate,
      limitIntervals: this.limitIntervals,
      status: this.status,
      merchantId: this.merchantId,
      kind: this.kind,
      limitUsageNumber: this.limitUsageNumber,
    });
  }

  public async del() {
    const res: any = await del(`/discounts/${this.id}`);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.code = data.code;
    this.rate = data.rate;
    this.limitIntervals = data.limitIntervals;
    this.activatedAt = data.activatedAt;
    this.status = data.status;
    this.cancelledAt = data.cancelledAt;
    this.merchantId = data.merchantId;
    this.kind = data.kind;
    this.limitUsageNumber = data.limitUsageNumber;
    this.appliedTime = data.appliedTime;
  }
}
