import { get, post } from '@/services/http';
import { DiscountModel } from '@/models/discount';
import List from './list';

interface SearchParams {
  merchantId?: number;
}

export class DiscountList extends List<DiscountModel> {
  public lastPage!: number;
  public mapData(data: any = []) {
    for (const s of data) {
      const item = new DiscountModel();
      item.mapData(s);
      this.add(item);
    }
  }

  public async fetch(
    page: number = 1,
    pageSize: number = 50,
    params: SearchParams = {},
    ) {
      const data: any = {
        page,
        per_page: pageSize,
        ...params,
      };
      const res: any = await get(`/discounts`, data);
      const items = res.data.rows || [];
      this.lastPage = Number((res.data.count / pageSize).toFixed(0));
      if (res.data.count / pageSize > this.lastPage) {
        this.lastPage = this.lastPage + 1;
      }
      for (const s of items) {
        const item: DiscountModel = new DiscountModel();
        item.mapData(s);
        this.add(item);
      }
  }
}
