
import { Component, Vue, Watch } from 'vue-property-decorator';
import SideBar from './components/SideBar.vue';

@Component({
  components: {
    SideBar,
  },
})
export default class App extends Vue {
  public isLogined: boolean = false;
  public hasPermission: boolean = false;

  public created() {
    this.checkLogin();
    this.checkPermission();
  }

  private checkLogin() {
    if (localStorage.getItem('token')) {
      this.isLogined = true;
      if (this.$route.name === 'sign-in') {
        this.$router.push('/');
      }
    } else {
      this.isLogined = false;
      this.$router.push('/sign-in');
    }
  }

  private checkPermission() {
    if (!['home', 'sign-in', 'store_list'].includes(`${this.$route.name}`)) {
      if (localStorage.getItem('email') === 'darius@assisty.ai') {
        this.hasPermission = true;
      } else {
        this.hasPermission = false;
      }
    } else {
      this.hasPermission = true;
    }
  }

  @Watch('$route.name')
  private onChangePage(value: any, oldValue: any) {
    this.checkLogin();
    this.checkPermission();
  }
}

