
import { Component, Vue } from 'vue-property-decorator';
import { FaqList } from '@/collections/faqs';

@Component({
  components: {
  },
})
export default class Faqs extends Vue {
  public isLoading: boolean = true;
  public faqs: FaqList = new FaqList();
  public loading: boolean = false;
  public success: boolean = false;

  public async created() {
    this.isLoading = true;
    await this.faqs.fetch();
    this.isLoading = false;
  }

  public async loadFaq() {
    this.loading = true;
    await this.faqs.load();
    this.loading = false;
    this.success = true;
    await new Promise((f) => setTimeout(f, 2000));
    this.success = false;
  }
}
