import { get, post, put, del } from '@/services/http';

export class StoreMetadataModel {
    public id: number;
    public storeId: number;
    public merchantId: number;
    public shopName: string;
    public storeUrl: string;
    public shopifyPlan: string;
    public status: string;
    public keyword: string;
    public country: string;
    public purpose: string;
    public lastActivityAt: string;
    public installedAt: string;
    public uninstalledAt: string;
    public trialEndAt: string;
    public occurredAt: string;
    public uninstallReason: string;
    public extra: any;
    public options: any;
    public createdAt?: string;
    public updatedAt?: string;
    public subType?: string;

    constructor(data: any = {}) {
        this.id = data.id;
        this.storeId = data.storeId;
        this.merchantId = data.merchantId;
        this.shopName = data.shopName;
        this.storeUrl = data.storeUrl;
        this.shopifyPlan = data.shopifyPlan;
        this.keyword = data.keyword;
        this.shopifyPlan = data.shopifyPlan;
        this.country = data.country;
        this.purpose = data.purpose;
        this.lastActivityAt = data.lastActivityAt;
        this.installedAt = data.installedAt;
        this.uninstalledAt = data.uninstalledAt;
        this.trialEndAt = data.trialEndAt;
        this.occurredAt = data.occurredAt;
        this.uninstallReason = data.uninstallReason;
        this.extra = data.extra || {};
        this.options = data.options || {};
        this.status = data.status;
    }

    public async fetch() {
        const res: any = await get(`/store_metadata/${this.id}`);
        this.mapData(res.data);
    }

    public async save() {
        try {
            const res: any = await post('/store_metadata', {
                id: this.id,
                uninstallReason: this.uninstallReason,
                extra: this.extra,
            });
            if (!this.id) {
                this.mapData(res.data);
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    public mapData(data: any = {}) {
        this.id = data.id;
        this.storeId = data.storeId;
        this.merchantId = data.merchantId;
        this.shopName = data.shopName;
        this.storeUrl = data.storeUrl;
        this.shopifyPlan = data.shopifyPlan;
        this.keyword = data.keyword;
        this.shopifyPlan = data.shopifyPlan;
        this.country = data.country;
        this.purpose = data.purpose;
        this.lastActivityAt = data.lastActivityAt;
        this.installedAt = data.installedAt;
        this.uninstalledAt = data.uninstalledAt;
        this.trialEndAt = data.trialEndAt;
        this.occurredAt = data.occurredAt;
        this.uninstallReason = data.uninstallReason;
        this.extra = data.extra || {};
        this.options = data.options || {};
        this.status = data.status;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;

        if (this.shopifyPlan === 'Free') {
            this.subType = 'Free';
        } else {
            if (this.status === 'UNINSTALLED') {
                this.subType = new Date(data.uninstalledAt) > new Date(data.trialEndAt) ? 'Subscriber' : 'Trial';
            } else {
                this.subType = new Date() > new Date(data.trialEndAt) ? 'Subscriber' : 'Trial';
            }
        }
    }
}
