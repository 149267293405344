
import { Component, Vue } from 'vue-property-decorator';
import { PermissionModel } from '@/models/permission';
import { PermissionList } from '@/collections/permissions';

@Component({
  components: {
  },
})
export default class Permissions extends Vue {
  public isLoading: boolean = true;
  public currentPage: number = 1;
  public perPage: number = 50;
  public lastPage: number = 1;
  public permissions: PermissionList = new PermissionList();

  public async created() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.perPage = Number(this.$route.query.per_page) || 50;
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  }

  public async fetchData() {
    this.permissions = new PermissionList();
    await this.permissions.fetch(
      this.currentPage,
      this.perPage,
    );
    this.lastPage = this.permissions.lastPage;
  }

  public viewReport(item: PermissionModel) {
    this.$router.push(`/template_reports/${item.id}`);
  }
}
