
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { FilterColumnList } from "@/collections/filter_columns";
import { MeasureModel } from "@/models/measure";
import { DimensionModel } from "@/models/dimension";
import { AnalyticsTypeModel } from "@/models/analytics_type";
import { Container, Draggable } from "vue-smooth-dnd";
import { DimensionColumnList } from "@/collections/dimension_columns";
import { ReportModel } from "@/models/report";
import { TemplateReportModel } from "@/models/template_report";

@Component({
  components: {
    Container,
    Draggable,
  },
})
export default class ColumnSelector extends Vue {
  @Prop({ default: null })
  public value!: any[];
  public columnSelector: boolean = false;
  @Prop({ default: false })
  public columnSelectorMenu!: boolean;
  public isLoadingColumn: boolean = false;
  public filterColumns: FilterColumnList = new FilterColumnList();
  @Prop({ default: () => new TemplateReportModel() })
  public templateReport!: TemplateReportModel;
  @Prop({ default: "" })
  public pivotOption!: string;
  public reportColumns: any = [];
  @Prop({ default: [] })
  public selectedReportColumns!: any;
  public selectingItems: any = [];
  public dimensionColumns: DimensionColumnList = new DimensionColumnList();
  public columnGroups: any = [];
  public dialogAddDimensionColumn: boolean = false;
  public pivotColumns: any = [];
  public pivotDimensions: any = [];
  public pivotMeasures: any = [];
  public dialogPivotOption: boolean = false;
  public selectedPivotMeasure: any = [];
  public selectedPivotColumn: any = [];
  public created() {
    this.initDimensionColumn();
    this.initPivotColumns();
  }

  public async reloadColumn() {
    this.isLoadingColumn = true;
    const reportModel: ReportModel = new ReportModel();
    reportModel.measure = this.templateReport.measure.code;
    reportModel.dimension = this.templateReport.dimension.code;
    if (
      this.templateReport.analyticsType &&
      this.templateReport.analyticsType.code
    ) {
      reportModel.analyticsType = this.templateReport.analyticsType.code;
    }

    if (this.templateReport.pivotOption) {
      reportModel.pivotOption = this.templateReport.pivotOption;
    }
    try {
      await reportModel.fetch();
      if (reportModel.reportColumns && reportModel.reportColumns.length > 0) {
        this.reportColumns = reportModel.reportColumns;
      }
    } catch (e) {
      // there is something wrong
    }
    this.isLoadingColumn = false;
  }

  public async initDimensionColumn() {
    this.columnGroups = [
      { text: "Order Details", value: "order_detail", items: [] },
      { text: "Refunds", value: "refunds", items: [] },
      { text: "Product", value: "product", items: [] },
      { text: "Fulfillment", value: "fulfillment", items: [] },
      { text: "Tax", value: "tax", items: [] },
      { text: "Discounts", value: "discounts", items: [] },
      { text: "Shipping", value: "shipping", items: [] },
      { text: "Transaction", value: "transaction", items: [] },
      { text: "Customer Joureny", value: "customer_journey", items: [] },
      { text: "Billing / Payment", value: "billing_payment", items: [] },
      { text: "Customer", value: "customer", items: [] },
      { text: "Client Details", value: "client_details", items: [] },
      { text: "Presentments", value: "presentments", items: [] },
      { text: "Staffs", value: "staff", items: [] },
      { text: "Store Location", value: "store_location", items: [] },
      { text: "Sales Channel", value: "sales_channel", items: [] },
      { text: "Vendor", value: "vendor", items: [] },
      { text: "datetime", value: "datetime", items: [] },
    ];

    this.dimensionColumns = new DimensionColumnList();
    await this.dimensionColumns.fetch();
    await this.refreshColumnGroup();
  }

  public initSelectingItems() {
    this.selectingItems = [];
    let selectedReportColumns: any = this.selectedReportColumns;
    if (this.value && this.value.length > 0) {
      selectedReportColumns = this.value;
    }

    this.selectingItems = [];
    for (const item of selectedReportColumns) {
      this.selectingItems.push({ column: item, value: true });
    }
    for (const item of this.reportColumns) {
      if (!this.isSelectedColumn(item.code)) {
        this.selectingItems.push({ column: item, value: false });
      }
    }
  }

  public get canAddPivotOption() {
    return this.selectedPivotColumn.code && this.selectedPivotMeasure.code;
  }
  public initPivotData() {
    for (const item of this.reportColumns) {
      if (item.code.indexOf("_pivot_") === -1) {
        if (["image"].includes(item.dataType)) {
          continue;
        }
        if (["text", "date", "datetime"].includes(item.dataType)) {
          this.pivotDimensions.push(item);
        } else {
          this.pivotMeasures.push(item);
        }
      }
    }

    for (const group of this.columnGroups) {
      for (const item of group.items) {
        if (["image"].includes(item.data_type)) {
          continue;
        }
        if (["text", "date", "datetime"].includes(item.data_type)) {
          this.pivotDimensions.push(item);
        } else {
          this.pivotMeasures.push(item);
        }
      }
    }
  }

  public initPivotColumns() {
    this.pivotColumns = [];
    if (this.pivotOption) {
      const pivotItems = this.pivotOption.split("_pivot_");
      for (const item of pivotItems) {
        const options = item.split("_cols_");
        if (options.length >= 2) {
          const columnCode: string = options[0]
            .replace("_rows_", "")
            .replace("+", " ");
          const measureCode = options[1].replace("+", " ");
          const columnValue = this.getReportColumnByCode(columnCode);
          const measureValue = this.getReportColumnByCode(measureCode);
          if (columnValue && measureValue) {
            this.pivotColumns.push({
              column: columnValue,
              measure: measureValue,
            });
          }
        }
      }
    }
  }

  public addPivotOption() {
    if (!this.selectedPivotColumn.code || !this.selectedPivotMeasure.code) {
      return;
    }
    this.pivotColumns.push({
      column: this.selectedPivotColumn,
      measure: this.selectedPivotMeasure,
    });
    this.updatePivotOption();
  }

  public deletePivotOption(option: any) {
    let newOptions: any = [];
    for (const item of this.pivotColumns) {
      if (
        item.column.code !== option.column.code ||
        item.measure.code !== option.measure.code
      ) {
        newOptions.push(item);
      }
    }
    this.pivotColumns = newOptions;
    this.updatePivotOption();
  }

  public updatePivotOption() {
    let pivotStr: any = [];
    for (const pivot of this.pivotColumns) {
      const columnCode = pivot.column.code;
      const MeasureCode = pivot.measure.code;
      pivotStr.push(`_rows_${columnCode}_cols_${MeasureCode}`);
    }
    const pivotOption = pivotStr.join("_pivot_");
    this.$emit("update-pivot-option", pivotOption);
    this.columnSelector = false;
  }

  public getReportColumnByCode(code: string) {
    for (const column of this.reportColumns) {
      if (column.code === code) {
        return column;
      }
    }
    for (const dimension of this.dimensionColumns.items) {
      if (dimension.code === code) {
        return dimension;
      }
    }
    return null;
  }

  public async refreshColumnGroup() {
    const newColumnGroups: any = [];
    for (const group of this.columnGroups) {
      const items = await this.getDimensionColumns(group.value);
      if (items && items.length > 0) {
        group.items = items;
        newColumnGroups.push(group);
      }
    }
    this.columnGroups = newColumnGroups;
  }

  public async getDimensionColumns(group: string) {
    const columns: any = [];
    for (const item of this.dimensionColumns.items) {
      if (item.group_name === group) {
        if (!this.isAddedColumn(item.code)) {
          columns.push(item);
        }
      }
    }
    return columns;
  }

  public addColumn(dimensionColumn: any) {
    this.selectingItems.push({ column: dimensionColumn, value: true });
    this.dialogAddDimensionColumn = false;
    this.saveColumn();
    this.refreshColumnGroup();
    this.columnSelector = true;
  }
  public onDrop(result: any) {
    this.selectingItems = this.applyDrag(this.selectingItems, result);
  }

  public applyDrag(result: any, dragResult: any) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return result;
    }
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
  }

  public isSelectedColumn(code: string) {
    if (this.value && this.value.length > 0) {
      for (const column of this.value) {
        if (column.code === code) {
          return true;
        }
      }
    } else {
      if (this.selectedReportColumns && this.selectedReportColumns.length > 0) {
        for (const column of this.selectedReportColumns) {
          if (column.code === code) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public isAddedColumn(code: string) {
    if (!this.selectingItems || this.selectingItems.length === 0) {
      return false;
    }
    if (this.selectingItems && this.selectingItems.length > 0) {
      for (const item of this.selectingItems) {
        if (item.column.code === code) {
          return true;
        }
      }
    }
    return false;
  }

  public async showColumnSelector() {
    this.columnSelector = true;
    await this.reloadColumn();
    this.initSelectingItems();
    this.initPivotData();
  }

  public saveColumn() {
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        selectedItems.push(item.column);
      }
    }
    this.$emit("input", selectedItems);
    this.columnSelector = false;
  }

  @Watch("columnSelectorMenu", { immediate: true, deep: true })
  private async onColumnSelectorMenuChange(newVal: any) {
    if (newVal && newVal === true) {
      this.columnSelector = this.columnSelectorMenu;
    }
  }

  @Watch("reportColumns", { immediate: true, deep: true })
  private async onReportColumnsChange(newVal: any) {
    if (newVal) {
      if (this.reportColumns && this.reportColumns.length > 0) {
        this.initSelectingItems();
        this.initPivotColumns();
      }
    }
  }
}
