
import { Component, Vue, Watch } from 'vue-property-decorator';
import { StoreMetadataModel } from '@/models/store_metadata';
import { StoreModel } from '@/models/store';
import StoreNotes from '@/components/StoreNotes.vue';
import { FunnelEventModel } from '@/models/funnelEvent';
import { get } from '@/services/http';
import {NoteModel} from "@/models/note";


@Component({
  components: {
    StoreNotes,
  },
})
export default class StoreMetadataDetailPage extends Vue {
  public isLoading: boolean = false;
  public storeMetadata: StoreMetadataModel = new StoreMetadataModel();
  public messageError: string = '';
  public isError: boolean = false;
  public events: any[] = [];
  public uninstallReasons: string[] = [];
  public tags: string[] = [];
  public store: StoreModel = new StoreModel();
  public dialogConfirmPassedDataVerification: boolean = false;
  public dialogConfirmTestData: boolean = false;
  public dialogShowDataTestFailedAttributes: boolean = false;
  public dataTestFailedAttributes: any = {};
  public newNote: NoteModel = new NoteModel();
  public isLoadingTestData: boolean = false;
  public isLoadingSaveData: boolean = false;
  public testResult: string = '';

  public async created() {
    this.isLoading = true;

    await Promise.all([
      this.getStoreMetadata(),
      this.getStore(),
      this.getChurnReasons(),
      this.getTags(),
    ]);

    this.events.push({
      happenedAt: this.storeMetadata.installedAt,
      name: 'INSTALLED'
    });
    if (this.storeMetadata.uninstalledAt) {
      this.events.push({
        happenedAt: this.storeMetadata.uninstalledAt,
        name: 'UNINSTALLED'
      });
    }
    if (this.storeMetadata.status === 'UPGRADED') {
      this.events.push({
        happenedAt: this.storeMetadata.extra['upgraded'],
        name: 'UPGRADED'
      });
    }
    if (this.storeMetadata.trialEndAt) {
      this.events.push({
        happenedAt: this.storeMetadata.trialEndAt,
        name: 'TRIAL END'
      });
    }
    if (this.storeMetadata.options['funnelEvents'] && this.storeMetadata.options['funnelEvents'].length > 0) {
      this.events = this.events.concat(this.storeMetadata.options['funnelEvents']);
    }
    this.events.sort((a: { happenedAt: string | Date }, b: { happenedAt: string | Date }): number => {
      return new Date(b.happenedAt).getTime() - new Date(a.happenedAt).getTime();
    });
    this.isLoading = false;
  }

  public async getStoreMetadata() {
    this.storeMetadata = new StoreMetadataModel();
    this.storeMetadata.id = Number(this.$route.params.id);
    await this.storeMetadata.fetch();
  }

  public async getStore() {
    this.store = new StoreModel();
    this.store.id = Number(this.$route.params.id);
    await this.store.fetch();
  }

  public async getChurnReasons() {
    const res: any = await get('/store_metadata/churn_reasons');
    for (const item of res.data) {
      this.uninstallReasons.push(item['value']);
    }
  }

  public async getTags() {
    const res: any = await get('/store_metadata/tags');
    for (const item of res.data) {
      this.tags.push(item['value']);
    }
  }

  public async update() {
    this.isLoadingSaveData = true;
    const result = await this.storeMetadata.save();
    this.isLoadingSaveData = false;
  }

  public convertDateStr(dateStr: string) {
    if (!dateStr) {
      return '';
    }
    const date = new Date(dateStr);
    const timezoneOffset = 7 * 60;
    const adjustedDate = new Date(date.getTime() + timezoneOffset * 60 * 1000);
    const padZero = (num: number) => (num < 10 ? '0' : '') + num;
    return `${adjustedDate.getUTCFullYear()}-${padZero(adjustedDate.getUTCMonth() + 1)}-${padZero(adjustedDate.getUTCDate())} ${padZero(adjustedDate.getUTCHours())}:${padZero(adjustedDate.getUTCMinutes())}:${padZero(adjustedDate.getUTCSeconds())}`;

  }

  public colorStatus(status: string) {
    if (status === 'UNINSTALLED') {
      return 'red';
    } else if (status === 'INSTALLED') {
      return 'green';
    } else if (status === 'TRIAL END') {
      return 'orange';
    } else if (status === 'UPGRADED') {
      return 'green';
    }
    return 'blue';
  }

  public genLogrocket(storeUrl: string) {
    const baseUrl = "https://app.logrocket.com/igdczq/assisty/sessions";
    const filter = JSON.stringify([{
      type: "name",
      operator: {
        name: "is",
        type: "IS",
        hasStrings: true,
        autocompleteEnabled: true
      },
      strings: [storeUrl]
    }]);
    const encodedFilter = encodeURIComponent(filter);
    const timeRange = 2592000000; // 30 days in milliseconds

    return `${baseUrl}?filters=${encodedFilter}&timeRange=${timeRange}`;
  }

  public async loginAsMerchant(storeId: number) {
    const token: string = await this.store.generateLink();
    window.open(`https://my.assisty.ai?hades=${token}`, '_blank');
  }

  public async confirmPassedDataVerification() {
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'DATA_VERIFICATION_HUMAN_PASSED',
      });
      await funnelEvent.create(this.store.id);
    } catch (error) {
      // skipped
    }
    this.dialogConfirmPassedDataVerification = false;
  }

  public async confirmTestData() {
    this.isLoadingTestData = true;
    const params = {
      merchant_id: this.storeMetadata.storeId,
      sales_period: 365,
      inventory_period: 1,
      tracking: false
    }
    const res: any = await get('/auto_test/validate', params);

    const message = `
    Test data result:
      - Test pass: ${res.test_pass}

      - order:
          API Count: ${res.order.api_count}
          OLAP Count: ${res.order.olap_count}
          Model Count: ${res.order.model_count || 'N/A'}
          Raw Count: ${res.order.raw_count || 'N/A'}
          Start Time: ${res.order.start_time}
          End Time: ${res.order.end_time}
          Tested At: ${res.order.tested_at}
          Time Range: ${res.order.time_range}
          Merchant ID: ${res.order.merchant_id}
          Shop Name: ${res.order.shop_name}
          OLAP: ${res.order.olap}
          Test Pass: ${res.order.test_pass}

      - product:
          API Count: ${res.product.api_count}
          Model Count: ${res.product.model_count}
          Raw Count: ${res.product.raw_count}
          Start Time: ${res.product.start_time || 'N/A'}
          End Time: ${res.product.end_time || 'N/A'}
          Tested At: ${res.product.tested_at}
          Time Range: ${res.product.time_range || 'N/A'}
          Merchant ID: ${res.product.merchant_id}
          Shop Name: ${res.product.shop_name}
          Test Pass: ${res.product.test_pass}

      - inventory:
          Merchant ID: ${res.inventory.merchant_id}
          Test Pass: ${res.inventory.test_pass}
          Message: ${res.inventory.message || 'N/A'}
          Logs: ${res.inventory.logs.length > 0 ? res.inventory.logs.join(', ') : 'No logs'}
    `;
    this.testResult = message;

    this.isLoadingTestData = false;
  }

  public async addTestResultToNote() {
    this.isLoadingTestData = true;
    this.newNote.merchantId = this.storeMetadata.storeId;
    this.newNote.note = this.testResult;
    await this.newNote.save();

    await (this.$refs.storeNotesComponent as any).fetchNotes();
    this.dialogConfirmTestData = false;
    this.isLoadingTestData = false;
    this.testResult = '';
  }

  public removeTagValue(item: string) {
    const newValues = this.storeMetadata.extra['tags'];
    if (newValues && newValues.length > 0) {
      const itemIndex = newValues.indexOf(item);
      if (itemIndex !== -1) {
        newValues.splice(itemIndex, 1);
      }
      this.storeMetadata.extra['tags'] = newValues;
    }
  }

}
