
import { Component, Vue } from 'vue-property-decorator';
import { StoreList } from '@/collections/stores';
import { StoreModel } from '@/models/store';

@Component({
  components: {
  },
})
export default class Stores extends Vue {
  public isLoading: boolean = true;
  public stores: StoreList = new StoreList();
  public currentPage: number = 1;
  public perPage: number = 50;
  public lastPage: number = 1;
  public keyword: string = '';
  public dialog: boolean = false;

  public async created() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.perPage = Number(this.$route.query.per_page) || 50;
    await this.fetchData();
  }

  public async fetchData() {
    this.isLoading = true;
    this.stores = new StoreList();
    await this.stores.fetch(
      this.currentPage,
      this.perPage,
      {
        keyword: this.keyword,
      },
    );
    this.lastPage = this.stores.lastPage;
    this.isLoading = false;
  }

  public async loginAsMerchant(store: StoreModel) {
    const token: string = await store.generateLink();
    window.open(`https://my.assisty.ai?hades=${token}`, '_blank');
  }

  public async upgradeEtlSchedule(store: StoreModel) {
    await store.upgradeEtlSchedule();
  }

  public async unlockReports(store: StoreModel) {
    await store.unlockReports();
  }

  public async unlockDownload(store: StoreModel) {
    await store.unlockDownload();
  }

  public async bugReport(store: StoreModel) {
    this.dialog = true;
    await store.bugReport();
    this.dialog = false;
  }

  public clearFiler() {
    this.keyword = '';
    this.currentPage = 1;
    this.fetchData();
  }
}
