
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DiscountModel } from '@/models/discount';

@Component({
  components: {
  },
})
export default class TemplateReportEditPage extends Vue {
  public isLoading: boolean = true;
  public discount: DiscountModel = new DiscountModel();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    this.isLoading = true;
    this.discount.id = Number(this.$route.params.id);
    await this.discount.fetch();
    this.isLoading = false;
  }

  public async cancelDiscount() {
    await this.discount.del();
    await this.$router.push('/discounts');
  }

  public async updateDiscount() {
    await this.discount.update();
    await this.$router.push('/discounts');
  }
}
