import axios from 'axios';
import router from '@/router';
import store from '../store';
import { Location } from 'vue-router';
// import * as Sentry from '@sentry/browser';

const host = process.env.VUE_APP_BACKEND_API_URL;

// build http header
function buildHeader(): { [key: string]: string } {
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Accept': 'application/json',
  };
}

export let ax = axios.create({
  baseURL: host,
  headers: buildHeader(),
  timeout: 50000,
  responseType: 'json',
  transformRequest: [
    (data) => {
      return data;
    },
  ],
  transformResponse: [
    (data) => {
      if (!data) {
        const msg = 'Unknown Error';
        throw new Error(msg);
      }
      return data;
    },
  ],
});

ax.interceptors.response.use((config) => {
  return config;
}, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.clear();
    store.commit('logoutSuccess');
    router.push('/');
  }
  // Sentry.captureException(error);
  return Promise.reject(error);
});

/* tslint:disable-next-line */
function processData(data?: any) {
  if (data instanceof FormData) {
    // data.append('token', token)
  } else {
    // data.token = token
  }

  return data;
}

export function get<T>(url: string, data?: any): Promise<T> {
  // const identity: any = JSON.parse(localStorage.getItem('token') as string);
  const identity: string = localStorage.getItem('token') || '';
  return ax
    .get(url, {
      params: processData(data),
      headers: {
        'Authorization': identity ? `Bearer ${identity}` : '',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
    .then((res) => {
      if (typeof res.data === 'string') {
        return JSON.parse(res.data);
      } else {
        return res.data;
      }
    });
}

export function post<T>(url: string, data?: any, isForm: boolean = false, headers?: any): Promise<T> {
  // const identity: any = JSON.parse(localStorage.getItem('token') as string);
  const identity: string = localStorage.getItem('token') || '';
  data = processData(data);
  let contentType = 'application/json';

  if (isForm) {
    // contentType = 'application/form-data';
    contentType = 'multipart/form-data';
  } else {
    data = JSON.stringify(data);
  }

  return ax
    .post(url, data, {
      headers: {
        'Authorization': identity ? `Bearer ${identity}` : '',
        'Content-Type': contentType,
        ...headers,
      },
    })
    .then((res) => {
      if (typeof res.data === 'string') {
        return JSON.parse(res.data);
      } else {
        return res.data;
      }
    });
}

export function put<T>(url: string, data?: any): Promise<T> {
  // const identity: any = JSON.parse(localStorage.getItem('token') as string);
  const identity: string = localStorage.getItem('token') || '';
  return ax
    .put(url, JSON.stringify(processData(data)), {
      headers: { Authorization: identity ? `Bearer ${identity}` : '' },
    })
    .then((res) => {
      if (typeof res.data === 'string') {
        return JSON.parse(res.data);
      } else {
        return res.data;
      }
    });
}

export function del<T>(url: string, data?: any): Promise<T> {
  // const identity: any = JSON.parse(localStorage.getItem('token') as string);
  const identity: string = localStorage.getItem('token') || '';
  return ax
    .delete(url, {
      data: JSON.stringify(processData(data)),
      headers: { Authorization: identity ? `Bearer ${identity}` : '' },
    })
    .then((res) => {
      if (typeof res.data === 'string') {
        return JSON.parse(res.data);
      } else {
        return res.data;
      }
    });
}
