import { get, post, put } from '@/services/http';
import { RuleList } from '@/collections/rules';

export class UserPackageModel {
  public id: number;
  public merchantId: number;
  public name?: string;
  public price: number;
  public discount: number;
  public durationLimitInIntervals?: number;
  public activatedAt?: string;
  public rules: RuleList;
  public status?: string;
  public chargeId?: number;
  public trialDay?: number;
  public trialEndAt?: string;
  public billingPackageId: number;
  public billingPackageVersion?: number;
  public cancelledAt?: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.merchantId = data.merchantId;
    this.billingPackageId = data.billingPackageId;
    this.rules = new RuleList();
    this.discount = data.discount || 0;
    this.price = data.price || 0;
  }

  public async save() {
    const res: any = await put(`/user_packages/${this.id}`, {
      rules: this.rules.items,
    });
    this.mapData(res.data);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.merchantId = data.merchantId;
    this.name = data.name;
    this.price = data.price;
    this.discount = data.discount;
    this.durationLimitInIntervals = data.durationLimitInIntervals;
    this.activatedAt = data.activatedAt;
    this.rules = new RuleList();
    this.rules.mapData(data.rules);
    this.status = data.status;
    this.chargeId = data.chargeId;
    this.billingPackageId = data.billingPackageId;
    this.billingPackageVersion = data.billingPackageVersion;
    this.cancelledAt = data.cancelledAt;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.trialDay = data.trialDay;
    this.trialEndAt = data.trialEndAt;
  }
}
