
import { Component, Vue } from 'vue-property-decorator';
import { RuleList } from '@/collections/rules';

@Component({
  components: {
  },
})
export default class Rules extends Vue {
  public isLoading: boolean = true;
  public rules: RuleList = new RuleList();

  public async created() {
    this.isLoading = true;
    await this.rules.fetch();
    this.isLoading = false;
  }
}
