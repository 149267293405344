import { get } from '@/services/http';

export class AnalyticsTypeModel {
  public id: number;
  public name: string;
  public code: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.luis_mapping;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }
}
