
import { Component, Vue } from 'vue-property-decorator';
import { PluginList } from '@/collections/plugins';
import { PluginModel } from '@/models/plugin';
import { del } from '@/services/http';

@Component({
  components: {
  },
})
export default class Plugins extends Vue {
  public isLoading: boolean = true;
  public plugins: PluginList = new PluginList();
  public loading: boolean = false;
  public success: boolean = false;
  public async created() {
    this.isLoading = true;
    await this.plugins.fetch();
    this.isLoading = false;
  }
  public deletePlugin(pluginId: number, index: number) {
    del(`plugin/${pluginId}`);
    this.plugins.items.splice(index, 1);
  }
  public async changeStatus(plugin: PluginModel) {
    await plugin.changeStatus();
  }

  public editPlugin(pluginId: number) {
    this.$router.push(`/plugin/edit/${pluginId}`);
  }


}
