
import { Component, Vue } from 'vue-property-decorator';
import { TemplateReportList } from '@/collections/template_reports';
import { MeasureList } from '@/collections/measures';
import { TagList } from '@/collections/tags';
import { DimensionList } from '@/collections/dimensions';
import { AnalyticsTypeList } from '@/collections/analytics_types';
import { TemplateReportModel } from '@/models/template_report';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { OpenaiModel } from '@/models/openai';
import { TagModel } from '@/models/tag';
import { TransitionGroupStub } from '@vue/test-utils';

@Component({
  components: {
  },
})
export default class TemplateReports extends Vue {
  public isLoading: boolean = true;
  public currentPage: number = 1;
  public perPage: number = 50;
  public lastPage: number = 1;
  public name: string = '';
  public templateReports: TemplateReportList = new TemplateReportList();
  public measures: MeasureList = new MeasureList();
  public tags: TagList = new TagList();
  public dimensions: DimensionList = new DimensionList();
  public analyticsTypes: AnalyticsTypeList = new AnalyticsTypeList();
  public measure: MeasureModel = new MeasureModel();
  public tag: TagModel = new TagModel();
  public dimension: DimensionModel = new DimensionModel();
  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public openaiModel: OpenaiModel = new OpenaiModel();
  public loadingOpenAI: any = [];
  public loading: boolean = false;
  public success: boolean = false;

  public async created() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.perPage = Number(this.$route.query.per_page) || 5000;
    this.isLoading = true;
    await this.tags.fetch();
    await this.measures.fetch();
    await this.dimensions.fetch();
    await this.analyticsTypes.fetch();
    await this.fetchData();
    this.isLoading = false;
  }

  public async generateDescription(report: TemplateReportModel) {
    const res: any = await this.openaiModel.fetchDescription('Give me a short description for this report (around 40 words length only): ' + report.name);
    const description = res.choices[0].text.replace(/[\n\s]+/g, ' ');
    report.instruction = description;
    await report.update();
  }

  public async changeLockStatus(report: TemplateReportModel) {
    if (report.isLocked === 'AVAILABLE') {
      report.isLocked = 'ISLOCKED';
    } else {
      report.isLocked = 'AVAILABLE';
    }
    await report.updateLockStatus();
  }

  public async fetchData() {
    this.templateReports = new TemplateReportList();
    const filterData = {
        measure: this.measure.code,
        dimension: this.dimension.code,
        analyticsType: this.analyticsType.code,
        tag: this.tag.id,
        name: '',
      };
    if (this.name) {
      filterData.name = this.name;
    }

    await this.templateReports.fetch(
      this.currentPage,
      this.perPage,
      filterData,
    );
    for (const templateReport of this.templateReports.items) {
      const selectedTags = [];
      for (const selectedTag of templateReport.tags.items) {
        for (const tag of this.tags.items) {
          if (Number(selectedTag.id) === tag.id) {
            selectedTags.push(tag);
          }
        }
      }
      templateReport.tags.items = selectedTags;

      for (const measure of this.measures.items) {
        if (templateReport.measure.code === measure.code && measure.code) {
          templateReport.measure = measure;
          break;
        }
      }

      for (const dimension of this.dimensions.items) {
        if (templateReport.dimension.code === dimension.code && dimension.code) {
          templateReport.dimension = dimension;
          break;
        }
      }

      for (const analyticsType of this.analyticsTypes.items) {
        if (templateReport.analyticsType.code === analyticsType.code &&
            analyticsType.code) {
          templateReport.analyticsType = analyticsType;
          break;
        }
      }
    }
    this.lastPage = this.templateReports.lastPage;
  }

  public clearFiler() {
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
    this.analyticsType = new AnalyticsTypeModel();
    this.name = '';
    this.tag = new TagModel();
    this.fetchData();
  }

  public viewReport(item: TemplateReportModel) {
    this.$router.push(`/template_reports/${item.id}`);
  }

  public async loadTemplateReport() {
    this.loading = true;
    await this.templateReports.load();
    this.loading = false;
    this.success = true;
    await new Promise((f) => setTimeout(f, 2000));
    this.success = false;
  }
}
