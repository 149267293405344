import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#007888',
        secondary: '#ff9800',
        accent: '#EEEEEE',
        error: '#ff5722',
        warning: '#ffc107',
        info: '#795548',
        success: '#8bc34a',
        background: '#FAFAFA',
        dark_primary: '#00BCD4',
        semi_dark_primary: '#00a0be',
        light_primary: '#99e4ed',
        gray: '#f5f5f5',
        dark_gray: '#777',
      },
    },
    dark: false,
    options: {
      customProperties: true,
    },
  },
});
