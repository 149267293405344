
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TagModel } from '@/models/tag';
import { TagTypeList } from '@/collections/tag_types';

@Component({
  components: {
  },
})
export default class TemplateReportEditPage extends Vue {
  public isLoading: boolean = true;
  public tag: TagModel = new TagModel();
  public tagTypes: TagTypeList = new TagTypeList();
  public messageError: string = '';
  public isError: boolean = false;

  public async created() {
    this.isLoading = true;
    await this.tagTypes.fetch();
    this.tag.id = Number(this.$route.params.id);
    await this.tag.fetch();
    for (const tagType of this.tagTypes.items) {
      if (tagType.id === this.tag.tagType.id) {
        this.tag.tagType = tagType;
        break;
      }
    }
    this.isLoading = false;
  }

  public async deleteTag() {
    await this.tag.del();
    await this.$router.push('/tags');
  }

  public async inactiveTag() {
    await this.tag.inactive();
    await this.$router.push('/tags');
  }

  public async activeTag() {
    await this.tag.active();
    await this.$router.push('/tags');
  }

  public async updateTag() {
    if (!this.tag.name || this.tag.name === '') {
      this.isError = true;
      this.messageError = 'Name is a required param';
      return;
    }

    await this.tag.update();
    await this.$router.push('/tags');
  }
}
