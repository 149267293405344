import { get, post, put, del } from '@/services/http';

export class TagTypeModel {
  public id: number;
  public name: string;
  public sort: number;
  public status: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.sort = data.sort;
    this.status = data.status;
  }

  public async fetch() {
    const res: any = await get(`/tag_types/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    const res: any = await post('/tag_types', {
      name: this.name,
      number: this.sort,
    });
    this.mapData(res.data);
  }

  public async update() {
    const res: any = await put(`/tag_types/${this.id}`, {
      name: this.name,
      number: this.sort,
    });
  }

  public async del() {
    const res: any = await del(`/tag_types/${this.id}`);
  }

  public async changeStatus() {
    if (this.status === 'ACTIVE') {
      await put(`/tag_types/inactive/${this.id}`);
    } else {
      await put(`/tag_types/active/${this.id}`);
    }
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.sort = data.number;
    this.status = data.status;
  }
}
