import { get, post, put, del } from '@/services/http';
import { RuleList } from '@/collections/rules';

export class PluginRuleModel {
  public id: number;
  public pluginId: number;
  public ruleId: number;
  public ruleCode: string;
  public value: string;
  public status: string;

  constructor(data: any = {}) {
    this.id = 0;
    this.pluginId = data.pluginId;
    this.ruleId = data.ruleId;
    this.ruleCode = data.ruleCode;
    this.value = data.value;
    this.status = data.status;
  }

  public async save() {
    if (!this.id) {
      const res: any = await post(`/plugin_rule/create`, {
        pluginId: this.pluginId,
        ruleId: this.ruleId,
        ruleCode: this.ruleCode,
        value: this.value,
      });
      this.mapData(res.data);
    } else {
      const res: any = await put(`/plugin_rule/${this.id}`, {
        pluginId: this.pluginId,
        ruleId: this.ruleId,
        ruleCode: this.ruleCode,
        value: this.value,
      });
    }
  }

  public delete() {
    if (this.id) {
      const res: any = del(`/plugin_rule/${this.id}`);
    }
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.pluginId = data.pluginId;
    this.ruleId = data.ruleId;
    this.ruleCode = data.ruleCode;
    this.value = data.value;
    this.status = data.status;
  }
}
