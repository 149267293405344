import { get, post, put, del } from '@/services/http';

export class ResourceModel {
    public id: number;
    public name: string;
    public type: string;
    public code: string;
    public validData: string;
    public ruleCode: string;
    public status: string;
    public options: object;
    public createdAt?: string;
    public updatedAt?: string;

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.type = data.type;
        this.code = data.code;
        this.validData = data.validData;
        this.ruleCode = data.ruleCode;
        this.options = data.options || {};
        this.status = data.status;
    }

    public async fetch() {
        const res: any = await get(`/resource/${this.id}`);
        this.mapData(res.data);
    }

    public async save() {
        try {
            const res: any = await post('/resource', {
                id: this.id,
                name: this.name,
                code: this.code,
                type: this.type,
                validData: this.validData,
                ruleCode: this.ruleCode,
                options: this.options,
                status: this.status,
            });
            if (!this.id) {
                this.mapData(res.data);
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    public mapData(data: any = {}) {
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.type = data.type;
        this.validData = data.validData;
        this.ruleCode = data.ruleCode;
        this.status = data.status;
        this.options = data.options;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }
}
