import { get } from '@/services/http';
import { NoteModel } from '@/models/note';
import List from './list';

export class NoteList extends List<NoteModel> {
  public async fetch(merchantId: number) {
    const res: any = await get(`/stores/${merchantId}/notes`, {});
    const notes = res.data || [];
    for (const s of notes) {
      const note = new NoteModel();
      note.mapData(s);
      this.add(note);
    }
    const compare = (a: NoteModel, b: NoteModel) => {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    };
    this.items = this.items.sort(compare);
  }
}
